import React, { useEffect, useState } from "react";
import '../util/config'
import { useSelector } from "react-redux";
import { getMessaggi, inviaMessaggio, inviaMessaggioWA, inviaMessaggioWALibero } from "../DAO/messaggiDAO";

export default function Messaggi(props){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata)

    const [messaggi, setmessaggi]=useState([])
    const [messaggio, setmessaggio] = useState('')
    const [ultimom, setultimom]= useState(25) //quante ore prima mi e' statto inviato l'ultimo messaggo di whatsapp ultimo messaggio

    function caricamessaggi(){
      async function carica(){
        const result = await getMessaggi(email,palestra.id,idanagselezionata);
        setmessaggi(result)
        if (result&&result.length>0&&result[0].idwa){
          console.log(result[0])
          console.log((new Date()))
          console.log(new Date(result[0].data))
          var diff =((new Date()).getTime() - new Date(result[0].data)) / 1000;
          diff /= (60 * 60);
          setultimom(diff)
        }
      }
      carica()
    }

    useEffect(() =>{
      caricamessaggi()
      },[idanagselezionata])

    function inviaMsgApp(){
        if (messaggio.trim()===''){
          alert("Messaggio obbligatorio");
          return;
      }
      async function invia(){
        const result = await inviaMessaggio(email,palestra.id,idanagselezionata,messaggio,0)
        alert(result)
        caricamessaggi()
        setmessaggio('')
      }
      invia()
    }

    function inviaMsgWA(){
      if (messaggio.trim()===''){
        alert("Messaggio obbligatorio");
        return;
    }

    async function invia(){
      const result = await inviaMessaggioWA(email,palestra.id,idanagselezionata,messaggio,0)
      alert(result)
      caricamessaggi()
      setmessaggio('')
    }
    invia()
  }

  function inviaMsgWALibero(){
    if (messaggio.trim()===''){
      alert("Messaggio obbligatorio");
      return;
  }

  async function invia(){
    const result = await inviaMessaggioWALibero(email,palestra.id,idanagselezionata,messaggio,0)
    alert(result)
    caricamessaggi()
    setmessaggio('')
  }
  invia()
}

    return (
        <span>
            <div className="row">
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-body">
                            {messaggi&&Array.isArray(messaggi)&&messaggi.length>0?
                            messaggi.map(messaggio=>(
                        <p className="card-text">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    {messaggio.messaggio}
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Data e ora</label>
                                    <input type="text" className="form-control" value={messaggio.data} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label>Letto</label>
                                    <input type="text" className="form-control" value={messaggio.letto} />
                                </div>
                                <div className="form-group col-md-1">
                                    <label>{
                                    messaggio.idwa?
                                    <img src="https://www.shareicon.net/data/128x128/2016/07/10/119959_whatsapp_512x512.png" width={30} />
                                    :
                                    <img src="https://www.shareicon.net/data/128x128/2015/11/08/668938_tools_512x512.png" width={30} />
                                    }</label>
                                    
                                </div>
                            </div>
                            <hr />
                        </p>
                        ))
                        :"Nessun messaggio trovato"}
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                  Nuovo messaggio<br />
                  <textarea class="form-control" 
                  name="messaggio" id="Messaggio" 
                  value={messaggio}
                  onChange={(e) => setmessaggio(e.target.value)}></textarea>

                  <button class="btn btn-primary" onClick={inviaMsgApp}>Invia messaggio APP</button>
                  {ultimom&&ultimom<24&&<button class="btn btn-primary" onClick={inviaMsgWALibero}>Invia messaggio WhatsApp Libero</button>}
                  {(!ultimom||ultimom>24)&&<button class="btn btn-primary" onClick={inviaMsgWA}>Invia messaggio WhatsApp Informativo (template Marketing)</button>}
                </div>
    </div>
    
</span>
);
  }