import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import TipologieEserciziSelect from "./TipologieEserciziSelect";
import {connect} from 'react-redux'

class NuovoEsercizioScheda extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            
	    	}
        this.update = this.update.bind(this);
        this.updateEsercizio = this.updateEsercizio.bind(this);
        this.inserisci = this.inserisci.bind(this);
	  }

	update(e) {
          this.setState({ [e.target.name]: e.target.value });
		
    }

    updateEsercizio(nome, valore) {
        this.setState({ [nome]: valore });
      
  }

    inserisci(e){
        e.preventDefault()
        const { peso,recupero, ripetizioni, serie, tut, ordine, numeroGiorno, note, idesercizio } = this.state;

	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','0001');
        formData.append('palestra',this.props.palestra.id);
	    formData.append('idschedafk', this.props.idscheda);
	    formData.append('peso', peso?peso:0);
		formData.append('recupero', recupero);
		formData.append('ripetizioni', ripetizioni);
		formData.append('serie', serie);
        formData.append('tut', tut);
        formData.append('ordine', ordine);
        formData.append('numeroGiorno', numeroGiorno);
        formData.append('note', note?note:'');
        formData.append('idesercizio', idesercizio);

	      axios.post(global.config.server+'/inserisciEsercizioScheda',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      });
    }
	render() {
	    return (
            <form onSubmit={this.inserisci}>
                id scheda: {this.props.idscheda}
                <div className="row form-group">
                    <div className="col">
                        <label>Giorno</label>
                        <input className="form-control" required={true} style={{padding:1}} type="text" name="numeroGiorno" value={this.state.numeroGiorno} onChange= {this.update} />
                    </div>
                    <div className="col">
                        <label>Ordine</label>
                        <input className="form-control" required={true} style={{padding:1}} type="text" name="ordine" value={this.state.ordine} onChange= {this.update} />
                    </div>
                    <div className="col-xs col-sm w-100"></div>
                    <div className="col">
                        <label>Serie</label>
                        <input className="form-control" required={true} style={{padding:1}} type="text" name="serie" value={this.state.serie} onChange= {this.update} />
                    </div>
                    
                    <div className="col">
                        <label>Recupero</label>
                        <input className="form-control" required={true} style={{padding:1}} type="text" name="recupero" value={this.state.recupero} onChange= {this.update} />
                    </div>
                    <div className="col-xs col-sm w-100"></div>
                    <div className="col">
                        <label>T.U.T.</label>
                        <input className="form-control" style={{padding:1}} ype="text" name="tut" value={this.state.tut} onChange= {this.update} />
                    </div>
                    
                    <div className="col-xs col-sm w-100"></div>
                    <div className="col-md-2 col-lg-2">
                        <label>Note</label>
                        <textarea className="form-control" type="text" name="note" value={this.state.note} onChange= {this.update} />
                    </div>
                    <div className="col">
                        <label>Ripetizioni</label>
                        <input className="form-control" required={true} style={{padding:1}} type="text" name="ripetizioni" value={this.state.ripetizioni} onChange= {this.update} />
                    </div>
                    <div className="col">
                        <label>Peso</label>
                        <input className="form-control" style={{padding:1}} type="text" name="peso" value={this.state.peso} onChange= {this.update} />
                    </div>
                    <div className="col-xs col-sm w-100"></div>
                    <div className="col-md-4 col-lg-4">
                    <label>Tipo Esercizio</label>
                        <TipologieEserciziSelect tipologie={this.props.tipologieEsercizi} selected={this.state.idesercizio} name={"idesercizio"} update={this.updateEsercizio}/>
                    </div>

                    <div className="col">
                        <button className="btn btn-info" type="submit" >Inserisci</button>
                    </div>
            </div>
            </form>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato
        }
    }
    
    export default connect(mapStateToProps, null)(NuovoEsercizioScheda)