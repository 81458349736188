import React from "react";
import axios from 'axios';
import Utente from '../anagrafica/Utente';
import serverInfo from "../util/globals";
export default class RiquadroRisRicAnagrafiche extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        nome:'',
        cognome:'',
        sesso:'',
        datanascita:'',
        valide:'',
        saldati:'0',
        tipoabbonamento:'',
        periodo:'',
        nomecognome:'',
        msgRecipients:[],
        messaggio:'',
        tutti:0,
        risultato:''
        };
        this.update = this.update.bind(this);
        this.addMsgList = this.addMsgList.bind(this);
        this.inviaMessaggioPush = this.inviaMessaggioPush.bind(this);
        this.inviaMessaggioWA = this.inviaMessaggioWA.bind(this);
        this.selezionatutti = this.selezionatutti.bind(this);
    }

    addMsgList(action, id){
        if (action==='add'){
            this.state.msgRecipients.push(id)
            console.log('aggiunto '+id)
            console.log(this.state.msgRecipients);
            console.log(this.state.tutti)
        }
        else{
            var anagIndex = this.state.msgRecipients.indexOf(id);
            this.state.msgRecipients.splice(anagIndex, 1);
            console.log('eliminato '+id)
            console.log(this.state.msgRecipients)
            console.log(this.state.tutti)
        }
    }


    componentDidMount(){
        this.setState({msgRecipients:[]});
        console.log("RiquadroRisRicAnagrafiche 1")
	  }
  
    componentDidCatch(error, info) {
          console.log(error, info);
        }
  
    update(e) {
        if (e.target.name==='tutti'){
            if (e.target.checked===true){
                this.setState({tutti:1})
            }
            else{
                this.setState({tutti:0})
            }
        }
        else{
            this.setState({ [e.target.name]: e.target.value, visible: true });
        }
    }

    inviaMessaggioPush(){
        if (this.state.msgRecipients.length===0
            &&!this.state.tutti
            &&this.state.tutti!==1){
            alert("selezionare almeno un destinatario");
            return;
        }
        if (this.state.messaggio.trim()===''){
            alert("Messaggio obbligatorio");
            return;
        }
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.idpalestra);
        //formData.append('id',localStorage.getItem('idanagrafica'));
        formData.append('msg', this.state.messaggio);
		formData.append('idAnagrafica', this.state.msgRecipients);
        formData.append('tutti', this.state.tutti);
        axios.post(serverInfo.namesec+'/msg/inviaMessaggiRest', formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
          },
        },)
        .then((result) => {
			console.log(result.data)
            alert(result.data)
        	this.setState({risultato:result.data});
        });
	  }

      inviaMessaggioWA(){
        if (this.state.msgRecipients.length===0
            &&!this.state.tutti
            &&this.state.tutti!==1){
            alert("selezionare almeno un destinatario");
            return;
        }
        if (this.state.messaggio.trim()===''){
            alert("Messaggio obbligatorio");
            return;
        }
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.idpalestra);
        //formData.append('id',localStorage.getItem('idanagrafica'));
        formData.append('msg', this.state.messaggio);
		formData.append('idAnagrafica', this.state.msgRecipients);
        formData.append('tutti', 0);
        axios.post(serverInfo.namesec+'/msg/inviawaRest', formData,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
          },
        },)
        .then((result) => {
			console.log(result.data)
            alert(result.data)
        	this.setState({risultato:result.data});
        });
	  }

      selezionatutti(checked){
        console.log(checked)
        let elements = document.getElementsByName("sendmessaggio");
        
    
        for(var i=0, n=elements.length;i<n;i++) {
            //elements[i].checked = checked;
            elements[i].click()
            // if (checked){
            //     this.addMsgList('add',Number(elements[i].id.replace('check_','')))
            // }
            // else{
            //     this.addMsgList('del',Number(elements[i].id.replace('check_','')))
            // }
          }
      }

    render() {
      return (
        <div className="table-responsive sezione">
            {this.props.anagrafiche&&Array.isArray(this.props.anagrafiche)&&this.props.anagrafiche.length>0?
                "Trovate "+this.props.anagrafiche.length+" anagrafiche"
            :null}
        <table className="table table-hover table-striped">
            <thead className="thead-dark">
                <tr>
                <th>id</th>
                <th>Cognome</th>
                <th>Nome</th>
                <th>Data di nascita</th>
                <th>Città</th>
                <th>Sesso</th>
                <th>Codice Fiscale</th>
                <th>Telefono</th>
                <th>Sottoscrizione</th>
                <th>Tesseramento</th>
                <th>Prestazioni/Acquisti</th>
                <th>
                <label>Seleziona per invio messaggio
                        <input type="checkbox" name="selectall" value="1" onClick={(e) => this.selezionatutti(e.target.checked)} />
                        </label>
                </th>
                </tr>
                
            </thead>
            <tbody>
                
            {this.props.anagrafiche&&Array.isArray(this.props.anagrafiche)&&this.props.anagrafiche.length>0?
                this.props.anagrafiche.map(utente=>(
                    <Utente key={utente.id} anagrafica={utente} link={this.props.link} addMsgList={this.addMsgList} />
                ))
                :"Nessuna angrafica trovata"}
                </tbody>
        </table>
        <div className="form-group sezione">
            <label htmlFor="Messaggio">Physicolab informa i gentili clienti che</label>
             
            <textarea className="form-control" 
            name="messaggio" id="Messaggio" 
            onChange={(e) => this.setState({messaggio: e.target.value})}>{this.state.messaggio}</textarea>
            <label>Invia messaggio a tutti i clienti
             <input
                type="checkbox"
                name="tutti"
                value="1"
                onChange={this.update}
                />
            </label>
            <br />
             <button className="btn btn-primary" onClick={this.inviaMessaggioPush}>Invia messaggio APP <img src="https://www.shareicon.net/data/128x128/2015/11/08/668938_tools_512x512.png" width={30} /></button>
            &nbsp;&nbsp;&nbsp;
            {(this.state.tutti!==1)&& <button className="btn btn-primary" onClick={this.inviaMessaggioWA}>Invia messaggio WhatsApp <img style={{backgroundColor:'white'}} src="https://www.shareicon.net/data/128x128/2016/07/10/119959_whatsapp_512x512.png" width={30} /></button>}
             
        </div>
       
    </div>      
        );
    }
  }