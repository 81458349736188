import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cercafatture, esportafatture } from "../DAO/fattureDAO";
import {navigate, A} from 'hookrouter'
import { setfatturericercate } from "../STORE/reducers/gestoreFattura";
import '../index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faDownload, faSearch, faEnvelope, faPlusCircle, faEdit} from '@fortawesome/free-solid-svg-icons'

var timeout=3000
var fatturedaesportare=[];
export default function RicercaFatture (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const fatturericercate = useSelector(state => state.gestoreFatture.fatturericercate);
    const dispatch = useDispatch();

    const[nome, setnome] = useState('')
    const[cognome, setcognome] = useState('')
    const[codicefiscale, setcodicefiscale] = useState('')
    const[numero, setnumero] = useState('')
    const[datada, setdatada] = useState('')
    const[dataa, setdataa] = useState('')
    const[nomecognomecodfisc, setnomecognomecodfisc] = useState('')
    const [totfatture, settotfatture] = useState(0)
    const [fatture, setfatture] = useState([])

    function testalo(inputcodfisc){
        let codfisc=inputcodfisc.substring(1,17)
        console.log('codfisc '+codfisc)
        setcodicefiscale(codfisc)
        
         setTimeout(() => {
            setnomecognomecodfisc('')
            setcodicefiscale(codfisc)

            ricercafatture(codfisc)
         }, 1000); 
    }

      function splitdati(value){
        if (value.indexOf('%')===0&&value.length>=30){
            clearTimeout(timeout);
            console.log(value)
            let inputcodfisc=value
            inputcodfisc=inputcodfisc.replace('. ','')
            inputcodfisc=inputcodfisc.replace('\n',' ')
            inputcodfisc=inputcodfisc.replace('\r',' ')
            testalo(inputcodfisc)
            //timeout = setTimeout(testalo, 1000);
        }
      }

    function ricercafatture(codfisc){
        console.log('ricercafatture '+codfisc)
        async function cercafatt(){
            try{
                setfatture([])
                fatturedaesportare=[]
                console.log('prefatture: '+codicefiscale)
            const result = await cercafatture(codfisc?'':nomecognomecodfisc, codfisc?'':nome, codfisc?'':cognome, codfisc?codfisc:codicefiscale, codfisc?'':numero, codfisc?'':datada, codfisc?'':dataa,palestra.palestra.id, email);
            console.log(result)
            setfatture(result)
            let totfatt=0;
            result.map(fatt => totfatt+=fatt.importoricevuta)
            settotfatture(totfatt)
            dispatch(setfatturericercate(result));
            }
            catch(error){
                console.log(error)
            }
        }
        cercafatt();
    }

    function esportafattura(checked, value){
        console.log(checked+' '+value)
        let fatturetemp=fatturedaesportare;
        if (checked){
            if (!fatturetemp.includes(value)){
                fatturetemp.push(value)
            }
        }
        else{
            fatturetemp=fatturetemp.filter(fatt => fatt!==value)
        }
        
        fatturedaesportare=fatturetemp;
    }

    function esportaemail(emailforexport){
        async function exportfatt(){
            const result= await esportafatture(fatturedaesportare, email, palestra.palestra.id, "IT"+palestra.palestra.piva+"_"+new Date().getFullYear()+""+(("0" + (new Date().getMonth() + 1)).slice(-2))+""+("0" + new Date().getDate()).slice(-2)+'.zip', emailforexport);
        }
        if (fatturedaesportare&&Array.isArray(fatturedaesportare)&&fatturedaesportare.length>0){
            exportfatt();
        }
        else{
            alert('Selezionare almeno una fattura')
        }
    }

    function esporta(){
        async function exportfatt(){
            const result= await esportafatture(fatturedaesportare, email, palestra.palestra.id, "IT"+palestra.palestra.piva+"_"+new Date().getFullYear()+""+(("0" + (new Date().getMonth() + 1)).slice(-2))+""+("0" + new Date().getDate()).slice(-2)+'.zip', '');
            //alert(result);
        }
        if (fatturedaesportare&&Array.isArray(fatturedaesportare)&&fatturedaesportare.length>0){
            exportfatt();
        }
        else{
            alert('Selezionare almeno una fattura')
        }
    }

    function selectnotexport(){
        let fatturetemp=fatture&&fatture.length>0?fatture:fatturericercate;
        for(let i=0; i<fatturetemp.length; i++){
            if (fatturetemp[i].idfatt){
                document.getElementById('export'+fatturetemp[i].idfatt).checked=false;
            }
        }
        fatturedaesportare=[];
        for(let i=0; i<fatturetemp.length; i++){
            if (fatturetemp[i].fedataexport&&fatturetemp[i].fedataexport!==''){
                if (fatturetemp[i].idfatt){
                    document.getElementById('export'+fatturetemp[i].idfatt).checked=false;
                    esportafattura(false,fatturetemp[i].idfatt)
                }
            }
            else{
                if (fatturetemp[i].idfatt){
                    document.getElementById('export'+fatturetemp[i].idfatt).checked=true;
                    esportafattura(true,fatturetemp[i].idfatt)
                }
            }

        }
    }

    function selectall(){
        let fatturetemp=fatture&&fatture.length>0?fatture:fatturericercate;
        console.log('fatturetemp')
        console.log(fatturetemp)
        for(let i=0; i<fatturetemp.length; i++){
            if (fatturetemp[i].idfatt){
                document.getElementById('export'+fatturetemp[i].idfatt).checked=true;
                esportafattura(true,fatturetemp[i].idfatt)
            }
        }
    }

    function setmailforexport(){
        let emailexp = prompt("Confermare l'email per l'export delle fatture:", palestra.palestra.id===112?'annalisa.studioarganese@hotmail.it':email);
        esportaemail(emailexp)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          ricercafatture();
        }
      }

    return (
    		<div className="container-fluid">
                <div className="row">
                    <div className="col-md-3"><h4>Ricerca fatture</h4></div>
                    <div className="col-md-3">
                        <A href="/annotatio-ins-fatt/" className="button" style={{padding:5, margin:5}}>
                            <FontAwesomeIcon icon={faPlusCircle} /> Inserisci fattura</A>
                    </div>
                </div>
                <div className="form-row sezione">
                    <div className="form-group col-md-4">
                    <label for="inputEmail4">Ricerca veloce</label>
                    <input type="text" 
                        autoFocus={true}
                        className="form-control"
                        placeholder="NOME COGNOME CODICE FISCALE" name="nomecognomecodfisc" 
                        value={nomecognomecodfisc} 
                        onKeyDown={handleKeyDown}
                        onChange={(e) =>{setnomecognomecodfisc(e.target.value); clearTimeout(timeout); timeout = setTimeout(splitdati(e.target.value), 2000);}} />
                    </div>
                   
                </div>
                <div className="sezione">
                <div className="form-row">
                    <div className="form-group col-md-4">
                    <label for="inputEmail4">Cod. Fiscale</label>
                    <input type="text" 
                        className="form-control"
                        onKeyDown={handleKeyDown}
                        placeholder="CODICE FISCALE" name="codicefiscale" value={codicefiscale} onChange={(e) =>{setcodicefiscale(e.target.value); clearTimeout(timeout); timeout = setTimeout(splitdati(e.target.value), 2000);}} />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="inputPassword4">Nome</label>
                    <input type="text"
                    className="form-control" onKeyDown={handleKeyDown}
                     placeholder="NOME" name="nome" value={nome} onChange={(e) =>setnome(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="inputPassword4">Cognome</label>
                    <input type="text" 
                     className="form-control" onKeyDown={handleKeyDown}
                     placeholder="COGNOME" name="cognome" value={cognome} onChange={(e) =>setcognome(e.target.value)} />

                    </div>
                </div>

                <div className="form-row">
                <div className="form-group col-md-4">
                    <label for="inputPassword4">Numero fattura</label>
                    <input type="text" 
                    className="form-control" onKeyDown={handleKeyDown}
                    placeholder="NUMERO" name="numero" value={numero} onChange={(e) =>setnumero(e.target.value)} />
</div>
<div className="form-group col-md-4">
                    <label for="inputPassword4">Data emissione DA</label>
                    <input type="DATE" 
                     className="form-control" onKeyDown={handleKeyDown}
                     placeholder="EMISSIONE DA" name="datada" value={datada} onChange={(e) =>setdatada(e.target.value)} />

</div>
<div className="form-group col-md-4">
                    <label for="inputPassword4">Data emissione A</label>
                    <input type="DATE" 
                    className="form-control" onKeyDown={handleKeyDown}
                    placeholder="EMISSIONE A" name="dataa" value={dataa} onChange={(e) =>setdataa(e.target.value)} />
</div>
</div>
</div>
                <div className="row">
                    <button type="button" className="btn btn-primary" onClick={() =>ricercafatture('')}><FontAwesomeIcon icon={faSearch} /> CERCA </button>
                </div>
                <hr />
                <div>
                    <table className="table table-hover" >
                        <thead>
                            <tr>
                            <th>Numero/anno</th>
                            <th>Data</th>
                            <th></th>
                            <th>Nome</th>
                            <th>Cognome</th>
                            <th>Cod fisc</th>
                            <th>Data nascita</th>
                            <th>Importo</th>
                            <th>Pagamento</th>
                            <th>Data export</th>
                            <th>
                                <button onClick={selectnotexport}>Seleziona non esportati</button>
                                <button onClick={selectall}>Seleziona tutti</button>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                        {(fatture&&fatture.length>0?fatture:fatturericercate).map(fattura => (
                            <tr key={fattura.idfatt}>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.numeroricevuta}/{fattura.annoricevuta }
                           </td>
                           <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.dataricevuta}
                            </td>
                            <td>
                            <A href={'/annotatio-ins-fatt/'+fattura.idanag} data-toggle="tooltip" title={"Inserisci nuova fattura per "+fattura.nome+" "+fattura.cognome}>
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </A>
                            </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.nome}
                                </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.cognome}
                            </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.piva}{fattura.codicefiscale}
                                </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                            {fattura.datanascita}
                            </td>
                            <td style={{cursor:"pointer", textAlign:'right'}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.importoricevuta}
                            </td>
                            <td style={{cursor:"pointer", textAlign:'left', backgroundColor:fattura.datapagamento?'#ffffff':'orange'}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                            {fattura.datapagamento?fattura.importopagamento+" (il "+fattura.datapagamento+" tramite "+fattura.tipopagamento+")":"Nessun pagamento registrato"}
                            </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.fedataexport}
                            </td>
                            <td>
                                {fattura.idfatt?
                                <input type="checkbox" name="export" id={"export"+fattura.idfatt} value={fattura.idfatt} onClick={(e) => esportafattura(e.target.checked, fattura.idfatt)} />
                                :""
                                }
                            </td>
                            </tr>
))}
<tr><td colSpan={7} style={{textAlign:'right'}}>Tot.</td><td style={{textAlign:'right'}}>{totfatture}</td><td colSpan={2}></td></tr>
                        </tbody>
                    </table>
                    {fatture.length>0&&
                    <div>
                        <button type="button" className="btn btn-secondary" onClick={esporta}>
                        <FontAwesomeIcon icon={faDownload} /> Download XML fatture selezionate
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-secondary" onClick={setmailforexport}>
                        <FontAwesomeIcon icon={faEnvelope} /> Invia XML fatture selezionate per email
                        </button>
                    </div>
                    }
                </div>
            </div>
    );
}