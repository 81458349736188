import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import TipologieEserciziSelect from "./TipologieEserciziSelect";
import {connect, useSelector} from 'react-redux'
import EsercizioSchedaSett from "./eserciziSalaAttrezzi/EsercizioSchedaSett";
import serverInfo from "../util/globals";
import { getDatiEsercizio } from "../DAO/schedesalaattrezziDAO";

export default function EsercizioScheda({idschedaesercizio_o, numeroGiorno_o, ordine_o, note_o, eserciziValoriSett_o, 
    esercizio_o, esercizio2_o, esercizio3_o, tipologieEsercizi, eliminaEsercizio})
    {

        const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
        const email = useSelector(state => state.gestoreUtente.emailloggato);
        
        const[numeroGiorno, setnumeroGiorno] = useState(numeroGiorno_o);
        const[ordine, setordine] = useState(ordine_o);
        const[note, setnote] = useState(note_o);
        const[eserciziValoriSett, seteserciziValoriSett] = useState(eserciziValoriSett_o);
        const[esercizio, setesercizio] = useState(esercizio_o);
        const[esercizio2, setesercizio2] = useState(esercizio2_o);
        const[esercizio3, setesercizio3] = useState(esercizio3_o);
        const[idschedaesercizio, setidschedaesercizio] = useState(idschedaesercizio_o);

        const [visible, setvisible]=useState(false)
        const [nascondidati, setnascondidati]=useState(false)
        const [messaggio, setmessaggio]=useState("")

        const [keytorefresh, setkeytorefresh] = useState(new Date())

    function refreshDatiEsercizi(){
        console.log("refresha idschedaesercizio "+idschedaesercizio)
        async function refresha() {
            //setmodificato(true)
            const result = await getDatiEsercizio(email, palestra.id, idschedaesercizio)
            setidschedaesercizio(result.idschedaesercizi)
            seteserciziValoriSett(result.eserciziValoriSett)
            
            //setmodificato(false)
        }
        refresha()
    }

    function aggiorna(){


	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','0001');
        formData.append('palestra',palestra.id);
	    formData.append('id', idschedaesercizio);
        formData.append('idesercizio', esercizio);
        formData.append('esercizio2', esercizio2);
        formData.append('esercizio3', esercizio3);
        formData.append('ordine', ordine?ordine:1);
        formData.append('numeroGiorno', numeroGiorno?numeroGiorno:'');
        formData.append('note', note?note:'');
	      axios.post(global.config.server+'/aggiornaEsercizioScheda/'+idschedaesercizio,formData)
	      .then((result) => {
		        setmessaggio(result.data);
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
                    })
                    setvisible(false);
		          //this.props.refresh();
		          
	      });
    }

    useEffect(() => {
        console.log("USEEFFETCT")
        setkeytorefresh(new Date())
    },[eserciziValoriSett])

	return (
            <div key={keytorefresh} className="row form-group table-responsive">
                <button onClick={()=>setnascondidati(!nascondidati)}>{nascondidati?"Mostra":"Nascondi"} dati esercizio</button>
                     <table className="table table-striped table-hover table-sm table-bordered">
                     <thead>
                         <tr>
                         <th rowSpan={2}>Giorno</th>
                         <th rowSpan={2}>Ordine</th>
                         <th rowSpan={2}>Settimana</th>
                         <th rowSpan={2}>Serie</th>
                         <th rowSpan={2}>Recupero</th>
                         <th rowSpan={2}>T.U.T.</th>
                         
                         <th colSpan={2} style={{textAlign:"center"}}>
                         <div class="form-group">
                            <small><label >Tipo eser. 1</label></small>
                                <TipologieEserciziSelect tipologie={tipologieEsercizi} idschedaesercizi={idschedaesercizio} name="esercizio" selected={esercizio} update={(name,value) => {setesercizio(value)}} />
                            </div>
                         </th>
                         <th colSpan={2} style={{textAlign:"center"}}>
                         <div class="form-group">
                        <small><label >Tipo eser. 2</label></small>
                            <TipologieEserciziSelect tipologie={tipologieEsercizi} idschedaesercizi={idschedaesercizio} selected={esercizio2} name="esercizio2" update={(name,value) => {setesercizio2(value)}}/>
                        </div>
                         </th>
                         <th colSpan ={2} style={{textAlign:"center"}}>

                         <div class="form-group">
                        <small><label >Tipo eser. 3</label></small>
                            <TipologieEserciziSelect tipologie={tipologieEsercizi} idschedaesercizi={idschedaesercizio} selected={esercizio3} name="esercizio3" update={(name,value) => {setesercizio3(value)}}/>
                        </div>
                         </th>
                         </tr>
                         <tr>
                         <th>Ripetiz.</th>
                        <th>Peso</th>
                        <th>Ripetiz.</th>
                        <th>Peso</th>
                        <th>Ripetiz.</th>
                        <th>Peso</th>
                         </tr>
                     </thead>
                     {!nascondidati&&
                    <tbody style={{height: 400, overflow:"scroll"}}>
                        <tr style={{verticalAlign:'top',textAlign:'center'}}>
                            <td rowSpan={16} style={{verticalAlign:'top',textAlign:'center'}}>
                                <div class="form-group">
                                   
                                    <input className="form-control" placeholder="giorno" required={true} style={{padding:1}} type="text" name="numeroGiorno" value={numeroGiorno} onChange= {(e) => setnumeroGiorno(e.target.value)} />
                                </div>
                            </td>
                            <td rowSpan={16} style={{verticalAlign:'top',textAlign:'center'}}>
                                <div class="form-group">
                                    <input className="form-control" placeholder="ordine" required={true} style={{padding:1}} type="text" name="ordine" value={ordine} onChange= {(e) => setordine(e.target.value)} />
                                </div>
                            </td>
                            {eserciziValoriSett[0]&&eserciziValoriSett[0].numsettimana===1&&
                            <EsercizioSchedaSett numSettimana={1} 
                            idschedaesercizio={idschedaesercizio}
                            serieo={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].serie:''}
                            recuperoo={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].recupero:''}
                            tuto={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].tut:''}
                            peso1o={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].dettSettimana&&eserciziValoriSett[0].dettSettimana[0]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].dettSettimana[0].peso:''}
                            peso2o={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].dettSettimana&&eserciziValoriSett[0].dettSettimana[1]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].dettSettimana[1].peso:''}
                            peso3o={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].dettSettimana&&eserciziValoriSett[0].dettSettimana[2]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].dettSettimana[2].peso:''}
                            ripetizioni1o={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].dettSettimana&&eserciziValoriSett[0].dettSettimana[0]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].dettSettimana[0].ripetizioni:''}
                            ripetizioni2o={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].dettSettimana&&eserciziValoriSett[0].dettSettimana[1]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].dettSettimana[1].ripetizioni:''}
                            ripetizioni3o={eserciziValoriSett&&eserciziValoriSett[0]&&eserciziValoriSett[0].dettSettimana&&eserciziValoriSett[0].dettSettimana[2]&&eserciziValoriSett[0].numsettimana===1?eserciziValoriSett[0].dettSettimana[2].ripetizioni:''}
                            refresh={refreshDatiEsercizi}
                            />}
                                    <td rowSpan={16}>
                    
                    <div className="col">
                        <button className="btn btn-danger" name={idschedaesercizio} onClick={()=> aggiorna()}>Salva</button>
                    </div>
                    
                        <button className="btn btn-danger" name={idschedaesercizio} onClick={() => eliminaEsercizio(idschedaesercizio)}>Elimina</button>
                        </td>
                        </tr>
                        {[...Array(16)].map((x, i) =>
                            i>0&&
                            <tr style={{verticalAlign:'top',textAlign:'center'}}>
                            <EsercizioSchedaSett numSettimana={i+1} 
                            idschedaesercizio={idschedaesercizio}
                            serieo={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1?eserciziValoriSett[i].serie:''}
                            recuperoo={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1?eserciziValoriSett[i].recupero:''}
                            tuto={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1?eserciziValoriSett[i].tut:''}
                            peso1o={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1&&eserciziValoriSett[i].dettSettimana&&eserciziValoriSett[i].dettSettimana[0]?eserciziValoriSett[i].dettSettimana[0].peso:''}
                            peso2o={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1&&eserciziValoriSett[i].dettSettimana&&eserciziValoriSett[i].dettSettimana[1]?eserciziValoriSett[i].dettSettimana[1].peso:''}
                            peso3o={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1&&eserciziValoriSett[i].dettSettimana&&eserciziValoriSett[i].dettSettimana[2]?eserciziValoriSett[i].dettSettimana[2].peso:''}
                            ripetizioni1o={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1&&eserciziValoriSett[i].dettSettimana&&eserciziValoriSett[i].dettSettimana[0]?eserciziValoriSett[i].dettSettimana[0].ripetizioni:''}
                            ripetizioni2o={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1&&eserciziValoriSett[i].dettSettimana&&eserciziValoriSett[i].dettSettimana[1]?eserciziValoriSett[i].dettSettimana[1].ripetizioni:''}
                            ripetizioni3o={eserciziValoriSett&&eserciziValoriSett[i]&&eserciziValoriSett[i].numsettimana===i+1&&eserciziValoriSett[i].dettSettimana&&eserciziValoriSett[i].dettSettimana[2]?eserciziValoriSett[i].dettSettimana[2].ripetizioni:''}
                            refresh={refreshDatiEsercizi}
                            
                            />
                            </tr>
                        )}
                    
                <tr>
                     <td colSpan={14}>
                     <div class="form-group">
                        <small><label >Note</label></small>
                        <textarea className="form-control" type="text" name="note" value={note} onChange= {(e) => setnote()} />
                        </div>
                        </td>
                </tr>
                </tbody>}
                       </table>
                    </div>
    )
    }