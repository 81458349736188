export function metabolismo(sesso, eta,peso){
    const molt=moltiplicatore(eta,sesso)
    const add=additore(eta, sesso)
    const tot=Math.round(((peso*molt)+add)*100)/100
    return tot
}

export function calculateage(dob, dataaquandocalcolareeta) { 
    var diff_ms = (dataaquandocalcolareeta?new Date(dataaquandocalcolareeta.split('/')[2]+'-'+dataaquandocalcolareeta.split('/')[1]+'-'+dataaquandocalcolareeta.split('/')[0]).getTime():Date.now) - new Date(dob.split('/')[2]+'-'+dob.split('/')[1]+'-'+dob.split('/')[0]).getTime();
    var age_dt = new Date(diff_ms); 
    var test=Math.abs(age_dt.getUTCFullYear() - 1970);
    return test
}

export function pesoideale(altezza, sesso){
    const pesoideale=Math.round(
        (Math.pow(((altezza)/100),2)*(sesso==='M'?22.1:20.6)*100)
        )/100
    return pesoideale
}

function  moltiplicatore(eta, sesso){
    if (sesso==='M'){
        if(eta<=29){
            return 15.3
        }
        else if(eta<=59){
            return 11.6
        }
        else if(eta<=74){
            return 11.9
        }
        else return 8.4
    }
    else{
        if(eta<=29){
            return 14.7
        }
        else if(eta<=59){
            return 8.7
        }
        else if(eta<=74){
            return 9.2
        }
        else return 9.8
    }
}

function additore(eta, sesso){
    if (sesso==='M'){
        if(eta<=29){
            return 679
        }
        else if(eta<=59){
            return 879
        }
        else if(eta<=74){
            return 700
        }
        else return 819
    }
    else{
        if(eta<=29){
            return 496
        }
        else if(eta<=59){
            return 829
        }
        else if(eta<=74){
            return 688
        }
        else return 624
    }
}

export function calcolaTotaleLordo(righe, idpalestra, setimportoiva, settotlordo, setimportopag) {
    console.log('calcolaTotaleLordo '+righe.length)
    var totimponibile=0;
    var totfattura=0;
    var totiva=0;
    let marcadabollopresente=false;
    let contributoenpabpresente=false;
    let indicecontributoenpab=-1;
    let valorecontributoenpab=0;
    righe.map((riga, index)=>{
        totfattura+=(parseFloat(riga.quantita)*parseFloat(riga.costo))
        if(!(riga.fenatura&&riga.fenatura==='N1')){
            totimponibile+=(parseFloat(riga.quantita)*parseFloat(riga.costo))
            totiva+=parseFloat(riga.quantita)*parseFloat(riga.costo)*riga.aliquotaiva/100
        }
        if(riga.descrizione.toUpperCase()==='MARCA DA BOLLO'){
            marcadabollopresente=true;
        }
        if(riga.descrizione.toUpperCase()==='CONTRIBUTO ENPAB'){
            contributoenpabpresente=true;
            indicecontributoenpab=index;
            valorecontributoenpab=riga.costo;
        }
    })
    //solo nel caso del dotto pastore aggiungoatomaticamente la marca da bollo
    if (totimponibile>77&&(idpalestra===112||idpalestra===113)&&!marcadabollopresente){
            righe.push({idrigafattura:'_' + Math.random().toString(36).substr(2, 9),
                quantita:1, 
                costo:2, 
                descrizione: "Marca da bollo",
                aliquotaiva:0,
                fenatura:'N1',
            });
            calcolaTotaleLordo(righe,idpalestra,setimportoiva,settotlordo,setimportopag);
            return;
    }
    if (idpalestra===113){
        if(contributoenpabpresente){
            if(valorecontributoenpab!==totimponibile*4/100){
                righe.splice(indicecontributoenpab,1)
                calcolaTotaleLordo(righe,idpalestra,setimportoiva,settotlordo,setimportopag);
                return;
            }
        }
        else{
            console.log('contributo enpab')
            righe.push({idrigafattura:'_' + Math.random().toString(36).substr(2, 9),
                quantita:1, 
                costo:totimponibile*4/100, 
                descrizione: "Contributo Enpab",
                aliquotaiva:0,
                fenatura:'N1',
            });
            calcolaTotaleLordo(righe,idpalestra,setimportoiva,settotlordo,setimportopag);
            return;
        }
    }
    console.log('calcolaTotaleLordo '+totfattura)
    setimportoiva(totiva)
    totfattura=totfattura+totiva;
    settotlordo(totfattura)
    setimportopag(totfattura)
    console.log('tot '+totfattura)
    //setkeytorefresh(new Date().getTime());
}