import React, { useState } from "react";
import '../util/config'
import TipologieCorso from "./tipologiaCorso/TipologieCorso";
import { Tabs, Tab } from "react-bootstrap";
import ListaCorsi from "./corsi/ListaCorsi";
import TipologieAbbonamentoInput from "./tipologiaAbbonamento/TipologieAbbonamentoInput";
import PeriodiInput from "./periodi/PeriodiInput";
import ListiniInput from "./listino/ListiniInput";
import ScontiInput from "./sconti/ScontiInput";
import ImportEsami from "../nutrizionista/esame/ImportEsami";

export default function Archivi (props) {

    return (
		<span>
				 <Tabs>
					 <Tab eventKey="tipocorsi" title="Categorie" mountOnEnter={true} unmountOnExit={true}>
					 <TipologieCorso email={props.email} />
					 </Tab>
					 <Tab eventKey="corsi" title="Servizi/Prodotti">
					 <ListaCorsi email={props.email} />
					 </Tab>
					 <Tab eventKey="tipoabb" title="Tipologia Abbonamento">
					 <TipologieAbbonamentoInput email={props.email} />
					 </Tab>
					 <Tab eventKey="periodi" title="Periodi">
					 <PeriodiInput email={props.email} />
					 </Tab>
					 <Tab eventKey="listini" title="Listini">
					 	<ListiniInput email={props.email} />
					 </Tab>
					 <Tab eventKey="sconti" title="Sconti">
					 <ScontiInput email={props.email} />
					 </Tab>
					 <Tab eventKey="sconti" title="Importa esami">
					 <ImportEsami />
					 </Tab>
				</Tabs>
				</span>
    );
}
