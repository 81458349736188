import React from "react";
import RiquadroRicAnagrafiche from '../riquadri/RiquadroRicAnagrafiche';
import NuovaAnagrafica from '../anagrafica/NuovaAnagrafica';
import RiquadroDettAnagrafica from '../riquadri/RiquadroDettAnagrafica';
import Toolbar from '../toolbar/Toolbar';
import AccessiGiornalieri from '../accessi/AccessiGiornalieri';
import Messaggi from '../messaggi/Messaggi';
import AbbonamentiUtente from '../riquadri/AbbonamentiUtente';
import Prenotazioni from '../prenotazioni/Prenotazioni';
import ViewCalendario from '../view/ViewCalendario';
import RicercaCassa from '../cassa/RicercaCassa';
import Dashboard from '../report/Dashboard';
import RicercaSchedaCC from '../schedasalaattrezzi/RicercaSchedaCC';
import RicercaSchedaSA from '../schedasalaattrezzi/RicercaSchedaSA';
import AnnotatioModules from '../view/AnnotatioModules';
import NuovoMovimentoCassa from '../cassa/NuovoMovimentoCassa';
import DettaglioScheda from '../schedasalaattrezzi/DettaglioScheda';
import ListaSchedeCompCorp from '../schedasalaattrezzi/composizionecorporea/ListaSchedeCompCorp';
import Ingressi from '../ingressi/Ingressi';
import Archivi from '../archivi/Archivi';
import DettMovimentoCassa from '../cassa/DettMovimentoCassa';
import Ordini from "../ordini/Ordini";
import Collaboratori from "../utenti/Collaboratori";
import TessereAnagrafica from "../tesserati/TessereAnagrafica";
import Uploadtesserati from "../tesserati/Uploadtesserati";
import RicercaFatture from "../fatture/RicercaFatture";
import InsFattura from "../fatture/InsFattura";
import InsPalestra from "../palestra/InsPalestra";
import Palestra from "../palestra/Palestra";
import EditFattura from "../fatture/EditFattura";
import GestioneFatture from "../fatture/GestioneFetture";
import DocumentiUtente from "../documenti/DocumentiUtente";
import CercaAlimenti from "../nutrizionista/CercaAlimenti";
import NuovaSchedadieta from "../nutrizionista/schededieta/NuovaSchedadieta";
import CercaSchededieta from "../nutrizionista/schededieta/CercaSchededieta";
import DettaglioSchedadieta from "../nutrizionista/schededieta/DettaglioSchedadieta";
import ListaTemplateDoc from "../archivi/templatedocumenti/ListaTemplateDoc";
import DettTemplateDoc from "../archivi/templatedocumenti/DettTemplateDoc";
import NewTemplateDoc from "../archivi/templatedocumenti/NewTemplateDoc";
import PalestreAssociateSelect from "../riquadri/PalestreAssociateSelect";
import DettaglioSchedulazione from "../calendario/DettaglioSchedulazione";
import NuovaSchedulazione from "../calendario/NuovaSchedulazione";
import ImportEsami from "../nutrizionista/esame/ImportEsami";

export const routes = {
    "*/anntt-adm-toolbar/": () => <Toolbar />,
    "*/annotatio/": () => <AnnotatioModules />,
    "/": () => <AnnotatioModules />,
    "*/annotatio-palass/": () => <PalestreAssociateSelect />,
    "*/annotatio-anag/": () => <RiquadroRicAnagrafiche />,
    "*/annotatio-newanag/": () => <NuovaAnagrafica />,
    "*/anntt-adm-dettanag/": () => <RiquadroDettAnagrafica />,
    "*/anntt-adm-abbs/": () => <AbbonamentiUtente />,
    "*/anntt-adm-docs/": () => <DocumentiUtente />,
    "*/anntt-adm-preno/": () => <Prenotazioni />,
    "*/anntt-adm-ingrs/": () => <Ingressi />,
    "*/anntt-adm-msg/": () => <Messaggi />,
    "*/annotatio-prods/": () => <ViewCalendario />,
    "*/annotatio-sched/": () => <DettaglioSchedulazione />,
    "*/annotatio-newsched/": () => <NuovaSchedulazione />,
    "*/annotatio-impesam/": () => <ImportEsami />,
    "*/annotatio-cassa/": () => <RicercaCassa />,
    "*/annotatio-newcassa/": () => <NuovoMovimentoCassa />,
    "*/anntt-adm-dettcassa/": () => <DettMovimentoCassa />,
    "*/annotatio-dashboard/": () => <Dashboard />,
    "*/annotatio-ordini/": () => <Ordini />,
    "*/annotatio-sa/": () => <RicercaSchedaSA />,
    "*/anntt-adm-dettsa/": () => <DettaglioScheda />,
    "*/annotatio-cc/": () => <RicercaSchedaCC />,
    "*/anntt-adm-dettcc/": () => <ListaSchedeCompCorp />,
    "*/annotatio-accessi/": () => <AccessiGiornalieri />,
    "*/annotatio-arch/": () => <Archivi />,
    "*/annotatio-collab/": () => <Collaboratori />,
    "*/anntt-adm-tess/": () => <TessereAnagrafica />,
    "*/annotatio-tess/": () => <Uploadtesserati />,

    "*/annotatio-fatt/": () => <GestioneFatture />,
    "*/annotatio-ric-fatt/": () => <RicercaFatture />,
    "*/annotatio-ins-fatt/:idanag": ({idanag}) => <InsFattura idanag={idanag}/>,
    "*/annotatio-ins-fatt/": () => <InsFattura />,

    "*/annotatio-dett-fatt/:idfatt": ({idfatt}) => <EditFattura idfatt={idfatt} />,
    "*/annotatio-dett-pal/": () => <Palestra />,
    "*/annotatio-ins-pal/": () => <InsPalestra />,

    "*/annotatio-alimenti/": () => <CercaAlimenti />,
    "*/annotatio-ali-newscheda/": () => <NuovaSchedadieta />,
    "*/annotatio-ali-schedadieta/": () => <CercaSchededieta />,
    "*/annotatio-ali-schedadieta/:id": ({id}) => <DettaglioSchedadieta id={id} />,

    "*/annotatio-doc/": () => <ListaTemplateDoc />,
    "*/annotatio-doc-dett/": () => <DettTemplateDoc />,
    "*/annotatio-doc-new/": () => <NewTemplateDoc />,
  };