import React, { useEffect, useState } from 'react';

import Scheduler, { Resource, View } from 'devextreme-react/scheduler';

import axios from 'axios';
import '../util/config'
import NuovaSchedulazione from './NuovaSchedulazione';
import DettaglioSchedulazione from './DettaglioSchedulazione';
import AppointmentTemplate from './AppointmentTemplate';
import { Button } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from "react-redux";
import { navigate } from 'hookrouter'
import { setcorsischedulazione, setsched, seteventoselezionato, setcalendario, setoraselezionata, setgiornoselezionato } from '../STORE/reducers/gestoreSchedulazione';

const views = ['day','week', 'month'];
const groups = ['location'];

export default function CalendarioAdmin({calendario}) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);

  const dispatch = useDispatch()

  const [cellDuration, setcellDuration] = useState(30);
  const [appuntamenti, setappuntamenti] = useState([]);
  const [places, setplaces] = useState([]);

  const [popupDettaglioVisible, setpopupDettaglioVisible] = useState(false);
  const [eventoSelezionato, seteventoSelezionato] = useState(null);

  const [settimanaOrigine, setsettimanaOrigine] = useState(0);
  const [settimanaDestinazione, setsettimanaDestinazione] = useState(0);
  const [popupNuovoVisible, setpopupNuovoVisible] = useState(false);
  const [corsi, setcorsi] = useState([])
  const [popupVisible, setpopupVisible] = useState(false)
  const [startDate, setstartDate] = useState(null)
  const [oraSelezionata, setoraSelezionata] = useState(null)
  const [currentDate, setcurrentDate] = useState()
  const [schedulerRef, setschedulerRef] = useState(React.createRef())
  const [status, setstatus] = useState([{
    text: 'NON PRENOTABILE',
    id: 'red',
    color: 'red',
  }, {
    text: 'PRENOTAZIONE CONFERMATA',
    color: 'green',
    id: 'green',
  },
  {
    text: 'DISPONIBILE',
    color: 'blue',
    id: 'blue',
  },
  {
    text: 'SOLO RISERVA',
    color: 'orange',
    id: 'orange',
  },
  {
    text: 'NON DISPONIBILE',
    color: 'gray',
    id: 'gray',
  },
  ]);
  
  function copiaSchedulazione(){
    const formData = new FormData();
    formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',palestra.id);
    formData.append('settimanaOrigine',settimanaOrigine)
    formData.append('settimanaDestinazione',settimanaDestinazione)
    formData.append('calendario',localStorage.getItem("calendario"));
    axios.post(global.config.server+'/copiaSchedulazioni',formData)
    .then((result) => {
      alert(result.data);
  }).catch(err => {
    if (err.response){
      alert(err.response.data.message);
    }
    else{
      alert(err)
    }
  });
  }

  function togglePopupDettaglio(){
    setpopupDettaglioVisible(!popupDettaglioVisible)
  }

  function togglePopupNuovo(){
   setpopupNuovoVisible(!popupNuovoVisible)
  }

  function nuovociccio(e){
    const dataselezionatat=e.cellData.startDate.toLocaleString("it-IT", {timeZone: "Europe/Rome"}).split(',')
    const oraSelezionatat= (dataselezionatat[1].split(':')[0]+":"+dataselezionatat[1].split(':')[1]).trim()
    dispatch(setcorsischedulazione(corsi))
    dispatch(setgiornoselezionato(e.cellData.startDate.toISOString().split('T')[0]))
    dispatch(setoraselezionata(oraSelezionatat))
    dispatch(setcalendario(localStorage.getItem("calendario")))
    navigate('/annotatio-newsched/')
  }

  function ciccio(e){
    dispatch(setcorsischedulazione(corsi))
    dispatch(setsched(e.appointmentData.schedulazione))
    dispatch(seteventoselezionato(e.appointmentData))
    dispatch(setcalendario(localStorage.getItem("calendario")))
    navigate('/annotatio-sched/',true)
  }

  function refreshSchedulazioni(){
    const formData2 = new FormData();
    formData2.append('website', global.config.domain);
    formData2.append('email',email);
    formData2.append('lic','000');
    formData2.append('palestra',palestra.id);
    formData2.append('calendario',localStorage.getItem("calendario"));

    axios.post(global.config.server+"/calendario",formData2)
    
    .then(response=>{
      setappuntamenti(response.data)
      setpopupDettaglioVisible(false)
    }).catch(err => {
        if (err.response){
        alert(err.response.data.message);
        }
        else{
          alert(err.response);
        }
    });
  }

  function onOptionChanged(e) {
    if (e.name === 'currentDate') {
      setcurrentDate(e.value);
    }
  }

  function showAppointmentPopup() {
    schedulerRef.current.instance.showAppointmentPopup();
  }

  useEffect(() => {
    const formData = new FormData();
  formData.append('website', global.config.domain);
  formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',palestra.id);
  formData.append('calendario',calendario?calendario:localStorage.getItem("calendario"));
  axios.post(global.config.server+"/getCorsiRest",formData,
  {withCredentials: true,
    credentials:'include',
      headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
      },
  },)
        .then(response=>{
          axios.post(global.config.server+"/getPlaces",formData,
          {withCredentials: true,
            credentials:'include',
              headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Allow-Credentials': 'true',
              'Authorization' :'Bearer '+window.$t
              },
          },)
              .then(response1=>{
                axios.post(global.config.server+"/calendario",formData,
                {withCredentials: true,
                  credentials:'include',
                    headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization' :'Bearer '+window.$t
                    },
                },)
                    .then(response2=>{
                      console.log(response.data)
                      setcorsi(response.data)
                      setplaces(response1.data)
                      setappuntamenti(response2.data)
                    console.log(response2.data)
                    }).catch(err => {
                        if (err.response){
                        alert(err.response.data.message);
                        }
                        else{
                        alert(err.response);
                        }
                    });
              
              }).catch(err => {
                  if (err.response){
                  alert(err.response.data.message);
                  }
                  else{
                  alert(err.response);
                  }
              });
        }).catch(err => {
            if (err.response){
              alert(err.response.data.message);
            }
            else{
              alert(err.response);
            }
          });
   
},[])

  
return (
      <React.Fragment>
        {popupDettaglioVisible&&eventoSelezionato?
        <DettaglioSchedulazione show={popupDettaglioVisible} 
        togglePopupDettaglio={togglePopupDettaglio}
        corsi={corsi}
        email={email}
        schedulazione={eventoSelezionato.schedulazione}
        eventoSelezionato={eventoSelezionato}
        refresh={refreshSchedulazioni}
        calendario={localStorage.getItem("calendario")}
        />
        :
        popupNuovoVisible?
        <NuovaSchedulazione show={popupVisible} 
      togglePopup={togglePopupNuovo}
      corsi={corsi}
      email={email}
      giornoSelezionato={startDate}
      oraSelezionata={oraSelezionata}
      refresh={refreshSchedulazioni}
      calendario={localStorage.getItem("calendario")}
      />
      :
      <div className='sezione'>
          copia tutti gli eventi della settimana <input type="week" name="settimanaOrigine" value={settimanaOrigine} onChange={(e) => setsettimanaOrigine(e.target.value)}  /> 
          nella settimana <input type="week" name="settimanaDestinazione" value={settimanaDestinazione} onChange={(e) => setsettimanaDestinazione(e.target.value)} /> <Button onClick={copiaSchedulazione}>Copia</Button>
          </div>      
      }
        <Scheduler
        visible={!(popupDettaglioVisible||popupNuovoVisible)}
          timeZone="Europe/Rome"
          dataSource={appuntamenti}
          //groups={groups}
          //groupByDate={groupByDate}
          views={views}
          adaptivityEnabled={false}
          onCellClick={nuovociccio}
          onAppointmentClick={ciccio}
          onAppointmentDblClick={ciccio}
          onOptionChanged={onOptionChanged}
          defaultCurrentView="week"
          cellDuration={cellDuration}
          appointmentRender={AppointmentTemplate}
          firstDayOfWeek={1}
          startDayHour={6}
          endDayHour={22}
          appointmentTemplate={AppointmentTemplate}
          showAllDayPanel={false}

          crossScrollingEnabled={false}
          showCurrentTimeIndicator={true}>
            <Resource
            dataSource={places}
            fieldExpr="location"
            label="Location"
            useColorAsDefault={false}
          />
          <Resource
            dataSource={status}
            fieldExpr="backgroundColor"
            label="Status"
            useColorAsDefault={true}
          />
          <View
            type="day"
            name="Day"
          />
          <View
            type="week"
            name="Week"
          /> 
          <View
            type="month"
            name="Month"
          />
        </Scheduler>
        
      </React.Fragment>
    );
  }