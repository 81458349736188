import React, { useEffect, useState } from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from 'hookrouter'
import '../index.css'
import { Button, Form } from "react-bootstrap";
import CorsiSelect from "../archivi/corsi/CorsiSelect";
import NuovoCorsoInput from "../archivi/corsi/NuovoCorsoInput";
import SelectTipologiaCorso from "../archivi/tipologiaCorso/SelectTipologiaCorso";
import { settipiprodotto } from "../STORE/reducers/gestoreTipiProdotto";

export default function NuovaSchedulazione() {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);

  const dispatch = useDispatch()
	const giornoSelezionato =  useSelector(state => state.gestoreSchedulazione.giornoselezionato);
	const oraSelezionata =  useSelector(state =>  state.gestoreSchedulazione.oraselezionata);
	const calendario  =  useSelector(state =>  state.gestoreSchedulazione.calendario);

	const [durata, setdurata] = useState(50)
	const [numMaxPrenotazioni, setnumMaxPrenotazioni] = useState(10)
	const [corso, setcorso] = useState('')
	const [giorno, setgiorno] = useState('')
	const [data, setdata] = useState('')
	const [ora, setora] = useState('')
	const [lun,setlun] = useState(0)
	const [mar, setmar]= useState(0)
	const [mer, setmer]= useState(0)
	const [gio, setgio]= useState(0)
	const [ven, setven]= useState(0)
	const [sab, setsab]= useState(0)
	const [dom, setdom]= useState(0)
	const [tipoPrenotazione, settipoPrenotazione] = useState('Pagamento Posticipato o in loco')
	const [showNuovoProdotto, setshowNuovoProdotto]=useState(false)
	const [duratagiornaliera, setduratagiornaliera] = useState(0)
	const [listatipi, setlistatipi] = useState([])
	const [tipocorso, settipocorso] = useState()
	const [listaCorsi, setlistaCorsi] = useState([])
	const [visible, setvisible] = useState(false)
	const [finoa, setfinoa] = useState('')
	const [finoalleore, setfinoalleore] = useState('')

	function refreshCorsi(idnuovocorso){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
		axios.post(global.config.server+"/getCorsiRest", formData,
		{
			headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Credentials': 'true',
			'Authorization' :'Bearer '+window.$t
			},
		},)
		.then(response=>{
			setlistatipi(response.data);
			dispatch(settipiprodotto(response.data))
			setlistaCorsi(response.data);

			response.data.map(tipcorso =>{
				var corsotemp=tipcorso.corsi.filter((corso)=>parseInt(corso.id)===parseInt(idnuovocorso))
				var tipoPre="";
				
				if (corsotemp&&corsotemp[0]){
					if (corsotemp[0].free){
						tipoPre="Free"
					}
					else if (corsotemp[0].abb){
						tipoPre="Abbonamento"
					}
					else if (corsotemp[0].pp){
						tipoPre="Pagamento Posticipato o in loco"
					}
					else if (corsotemp[0].pa){
						tipoPre="Pagamento Anticipato"
					}
					setcorso(idnuovocorso)
					setdurata(corsotemp[0].durata)
					 setnumMaxPrenotazioni(corsotemp[0].utenti)
					 settipoPrenotazione(tipoPre)
					return 0;//giusto per ritornare qualcosa perche' altrimenti dava un warning
				}
			})
			console.log("refreshcorsi");
			console.log(response.data);
			navigate('/annotatio-prods/')
		}).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
		  });
	}

	function update(e) {
		if(e.target.name==='corso'){
			if(e.target.value!==''){
				settipocorso('')
			listaCorsi&&listaCorsi.map(tipcorso =>{

				var corsotemp=tipcorso.corsi.filter((corso)=>parseInt(corso.id)===parseInt(e.target.value))
				var tipoPre="";
				if (corsotemp&&corsotemp[0]){
					if (corsotemp[0].free){
						tipoPre="Free"
					}
					else if (corsotemp[0].abb){
						tipoPre="Abbonamento"
					}
					else if (corsotemp[0].pp){
						tipoPre="Pagamento Posticipato o in loco"
					}
					else if (corsotemp[0].pa){
						tipoPre="Pagamento Anticipato"
					}
					setcorso(e.target.value);
					setdurata(corsotemp[0].durata)
					setnumMaxPrenotazioni(corsotemp[0].utenti)
					settipoPrenotazione(tipoPre)
					return 0;//giusto per ritornare qualcosa perche' altrimenti dava un warning
				}
			})
		}
		else{
			setcorso(e.target.value);
			setdurata('')
			setnumMaxPrenotazioni('')
			settipoPrenotazione('')
		}
		}
	  }

	  function aggiornatipo(idtipo){
		settipocorso(idtipo)
		setcorso('')
	}

	  useEffect(() =>{
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',email);
			formData.append('lic','000');
			formData.append('palestra',palestra.id);
			axios.post(global.config.server+"/getCorsiRest", formData,
			{
				headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+window.$t
				},
			},)
			.then(response=>{
				setlistatipi(response.data);
				setlistaCorsi(response.data);
				console.log(response.data,'listatipi')
				dispatch(settipiprodotto(response.data))
				setdata(giornoSelezionato)
				setora(oraSelezionata)
				settipoPrenotazione('Pagamento Posticipato o in loco')
				});
		},[])

      function handleSubmit(event) {
		event.preventDefault();
	    
		if (!tipoPrenotazione
			||tipoPrenotazione===''){
				alert('Tipo pagamento obbligatorio')
				return;
			}
		const formData = new FormData();
		
	    formData.append('durata', durata);
	    formData.append('numMaxPrenotazioni', numMaxPrenotazioni);
		formData.append('corso', corso);
		formData.append('data', giornoSelezionato);
        formData.append('ora', ora);
		formData.append('lun', lun);
		formData.append('mar', mar);
		formData.append('mer', mer);
		formData.append('gio', gio);
		formData.append('ven', ven);
		formData.append('sab', sab);
		formData.append('dom', dom);
		formData.append('duratagiornaliera', duratagiornaliera);
		formData.append('finoa', finoa?finoa:'');
		formData.append('finoalleore', finoalleore?finoalleore:'');
		formData.append('tipoPrenotazione', tipoPrenotazione);
		formData.append('website', global.config.domain);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
		formData.append('calendario',calendario);
		formData.append('idtipocorso',tipocorso&&tipocorso.id?tipocorso.id:'');
		var that=this
		axios.post(global.config.server+'/salvaNuovoCalendario',formData)
	      .then((result) => {
				alert(result.data)
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
					navigate('/annotatio-prods/')

	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
      }

	    return (
			<div>
				<h2>Nuova Schedulazione 
					<Button variant="secondary" onClick={() => navigate('/annotatio-prods/')}>
					Torna al calendario
				</Button></h2>
				<a name="schedulazione" />
				
				<form>
					<div className="row sezione">
						<div className="col-md">
						<Form.Group controlId="importo">
							<Form.Label>Giorno</Form.Label>
							<Form.Control type="date" placeholder="giorno" 
							name="data" value={giornoSelezionato} required={true} onChange={(e) => setgiorno(e.target.value)} />
						</Form.Group>
						</div>
						<div className="col-md">
						<Form.Group controlId="importo">
							<Form.Label>Ora</Form.Label>
							<Form.Control type="time" placeholder="ora" 
							name="ora" value={ora?ora:oraSelezionata} required={true} onChange={(e) => setora(e.target.value)} 
							min={palestra.orarioApertura1} max={palestra.orarioChiusura2}/>
						</Form.Group>
						</div>
					</div>
					<div className="row sezione">
					<div className="col-md">
							<Form.Group controlId="tipicorso">
								<Form.Label>Tipologie</Form.Label>
								
								<SelectTipologiaCorso updateTipo={aggiornatipo} corsi={listatipi} selected={tipocorso} />
							</Form.Group>
						</div>
						<div className="col-md">
							<br/>--oppure--</div>
						<div className="col-md">
							<Form.Group controlId="datapagamento">
								<Form.Label>Prodotto</Form.Label>
								<CorsiSelect onChange={update} corsi={listaCorsi} selected={corso} />
							</Form.Group>
						</div>
						<div className="col-md">
							Oppure inseriscine uno nuovo
							<NuovoCorsoInput
							email={email}
							refresh={refreshCorsi}
							/>
						</div>
					</div>
					<div className="row sezione">
						<div className="col-md">
							<Form.Group controlId="importo">
								<Form.Label>Durata (minuti)</Form.Label>
								<Form.Control type="number" placeholder="Durata" 
								name="durata" value={durata} required={true} onChange={(e) => setdurata(e.target.value)} 
								min={5}/>
								<label>Tutto il giorno <input type={"checkbox"} value={1} name={"duratagiornaliera"} checked={duratagiornaliera} 
						onClick={(e) => setduratagiornaliera(e.target.checked)}/></label>
							</Form.Group>
						</div>
						<div className="col-md">
						<Form.Group controlId="importo">
							<Form.Label>Max prenotazioni</Form.Label>
							<Form.Control type="number" placeholder="Max Prenotazioni" 
							name="numMaxPrenotazioni" value={numMaxPrenotazioni} required={true} onChange={(e)=>setnumMaxPrenotazioni(e.target.value)} 
							/>
						</Form.Group>
						</div>
						<div className="col-md">
						<Form.Group>
							<Form.Label>Tipo pagamento</Form.Label>
							<select className="form-control" value={tipoPrenotazione} name="tipoPrenotazione" required={true} onChange={(e) => settipoPrenotazione(e.target.value)}>
								<option value="">--tipo prenotazione--</option>
								<option value="Abbonamento">Abbonamento</option>
								<option value="Free">Free</option>
								<option value="Pagamento Anticipato">Pagamento Anticipato</option>
								<option selected={true} value="Pagamento Posticipato o in loco">Pagamento Posticipato</option>
							</select>
						</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-4">
						<div>Ripeti questa attività ogni</div>
							<div className="form-check">
								<input className="form-check-input" type="checkbox"
								name="lun" id="luncheck" value={1}
								checked={lun} onClick={(e) => setlun(e.target.checked?1:0)}/>
								<label className="form-check-label" for="luncheck">LUN </label>
							</div>
							<div className="form-check">
								<input className="form-check-input" type="checkbox"
								name="mar" value={1}
								checked={mar} onClick={(e) => setmar(e.target.checked?1:0)}
								 id="marcheck"	/>
								<label className="form-check-label" for="marcheck">MAR	</label>
							</div>
							<div className="form-check">
								<input className="form-check-input" type="checkbox"
								name="mer" value={1}
								checked={mer} onClick={(e) => setmer(e.target.checked?1:0)} id="mercheck"	/>
								<label className="form-check-label" for="mercheck">MER</label>
							</div>
							<div className="form-check">
								<input className="form-check-input" type="checkbox"
								name="gio" value={1}
								checked={gio} onClick={(e) => setgio(e.target.checked?1:0)} id="giocheck"
								/>
								<label className="form-check-label" for="giocheck">GIO	</label>
							</div>
							<div className="form-check">
								<input className="form-check-input" type="checkbox"
								name="ven" value={1}
								checked={ven} onClick={(e) => setven(e.target.checked?1:0)} id="vencheck"	/>
								<label className="form-check-label" for="vencheck">VEN	</label>
							</div>
							<div className="form-check">
								<input className="form-check-input" type="checkbox"
								name="sab" value={1}
								checked={sab} onClick={(e) => setsab(e.target.checked?1:0)} id="sabcheck"	/>
								<label className="form-check-label" for="sabcheck">SAB
								</label>
							</div>
							<div className="form-check">
								<input className="form-check-input" type="checkbox"
								name="dom" value={1}
								checked={dom} onClick={(e) => setdom(e.target.checked?1:0)} id="domcheck"	/>
								<label className="form-check-label" for="domcheck">DOM</label>
							</div>
						</div>
						<div className="col-md">
						<Form.Group controlId="importo">
							<Form.Label>Ripeti questa attività fino al giorno</Form.Label>
							<Form.Control type="date" placeholder="finoa" 
							name="finoa" value={finoa} onChange={(e) => setfinoa(e.target.value)} 
							/>
						</Form.Group>
						</div>
						<div className="col-md">
						<Form.Group controlId="importo">
							<Form.Label>Ripeti questa attività fino alle ore</Form.Label>
							<Form.Control type="time" placeholder="finoalleore" 
							name="finoalleore" value={finoalleore} onChange={(e) => setfinoalleore(e.target.value)} 
							/>
						</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-md">
						<Button variant="primary" onClick={handleSubmit}>
							Salva
						</Button>
						</div>
					</div>
					</form>
				
			</div>
	    );
	}