import React from "react";
import PeriodiSelect from "../periodo/PeriodiSelect";
import TipoAbbonamentoSelect from "../tipoabbonamento/TipoAbbonamentoSelect";
import "./RiquadroRicercaAnag.css"
import RiquadroRisRicAnagrafiche from "./RiquadroRisRicAnagrafiche";
import axios from 'axios';
import LoadingGif from "../util/LoadingGif";
import TipiPagamentoSelect from "../pagamenti/TipiPagamentoSelect";
import { useState } from "react";
import "../util/config";
import { useSelector, useDispatch } from 'react-redux';
import { setanagricercate } from "../STORE/reducers/gestoreUtente";
import Bottone from "../util/UI/Bottone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import {navigate} from 'hookrouter'
import '../index.css'

var timeout=3000
var inputnome=''
var inputcognome=''
export default function RiquadroRicAnagrafiche(props) {
    let istesserato=0;
    const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagricercate = useSelector(state => state.gestoreUtente.anagricercate);

    const dispatch = useDispatch();
    const [nome, setnome] = useState('');
    const [cognome, setcognome] = useState('');
    const [codicefiscale, setcodicefiscale] = useState('');
    const [emaildacercare, setemaildacercare] = useState('');
    const [datanascita, setdatanascita] = useState('');
    const [valide, setvalide] = useState('');
    const [saldati, setsaldati] = useState(0);
    const [tipoabbonamento, settipoabbonamento] = useState('');
    const [tipipagamento, settipipagamento] = useState('');
    const [periodo, setperiodo] = useState('');
    const [nomecognome, setnomecognome] = useState('');
    const [loading, setloading] = useState('');
    const [keytorefresh, setkeytorefresh] = useState(new Date());
    const [anagrafiche, setanagrafiche] = useState(null)
    //const [istesserato, setistesserato] = useState(0);
    const [dataDa, setdatada] = useState('');
    const [dataA, setdataA] = useState('');
    const [effettuatoDa, seteffettuatoDa] = useState('');
    const [effettuatoA, seteffettuatoA] = useState('');
    const [mostraAbbonamenti, setmostraAbbonamenti] = useState(false);

    const [tesserasanitaria, settesserasanitaria] = useState('');

    function handleSubmit(event){
		event.preventDefault();
        setloading(true);
        setanagrafiche([])
        
        if (event.detail ===1&&palestraanagrafica.id===1) {
            istesserato=1;
        }

        if (event.detail === 2||palestraanagrafica.id!==1) {
            istesserato=0;
            }
           cerca()
        
	  }

      function testalo(inputcodfisc){
        let codfisc=inputcodfisc.substring(1,17)
        //console.log('codfisc '+codfisc)
        setcodicefiscale(codfisc)
        
        // let valuetemp=inputcodfisc.split('?')
        //  //console.log('prima parte cod fisc '+valuetemp[0].substring(1,17))
        //  //console.log('seconda parte nome cognome '+valuetemp[0].substring(17))
        //  let nomecognometemp=valuetemp[0].substring(17).split(' ');
        //  nomecognometemp=nomecognometemp.filter(nc => nc!=='')
        //  if (nomecognometemp.length===2){
        //           setcognome(nomecognometemp[0])
        //           setnome(nomecognometemp[1])
        //       }
        //       else if (nomecognometemp.length>2){
        //           setcognome(nomecognometemp[0]+" "+nomecognometemp[1])
        //           let nometemp=''
        //           for (let i=2; i<nomecognometemp.length; i++){
        //             nometemp+=nomecognometemp[i]
        //             if (i<nomecognometemp.length-1){
        //                 nometemp+=' '
        //             }
        //           }
        //           setnome(nometemp)
        //       }
        //       else if (nomecognometemp.length===1){
        //           setcognome(nomecognometemp[0])
        //           setnome('')
        // }
         setTimeout(() => {
            setnomecognome('')
            settesserasanitaria('')
            cerca()
         }, 1000); 
    }

      function splitdati(value){
        if (value.indexOf('%')===0&&value.length>=30){
            clearTimeout(timeout);
            let inputcodfisc=value
            inputcodfisc=inputcodfisc.replace('. ','')
            inputcodfisc=inputcodfisc.replace('\n',' ')
            inputcodfisc=inputcodfisc.replace('\r',' ')
            testalo(inputcodfisc)
            //timeout = setTimeout(testalo, 1000);
        }
      }

       function cerca(){
        const formData = new FormData();
        formData.append('website', 'annotatio.online');
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestraanagrafica.id);
        formData.append('nomecognome', nomecognome?nomecognome:'');
        formData.append('nome', nome?nome:'');
        formData.append('cognome', cognome?cognome:'');
        formData.append('codfisc', codicefiscale?codicefiscale:'');
        formData.append('valide', valide?valide:'');
        formData.append('saldati', saldati?saldati:'');
        formData.append('idTipoAbbonamento', tipoabbonamento?tipoabbonamento:'');
        formData.append('idPeriodo', periodo?periodo:'');

        formData.append('dataDa', dataDa?dataDa:'');
        formData.append('dataA', dataA?dataA:'');
        formData.append('effettuatoDa', effettuatoDa?effettuatoDa:'');
        formData.append('effettuatoA', effettuatoA?effettuatoA:'');
        formData.append('mostraAbbonamenti', mostraAbbonamenti?mostraAbbonamenti:'');
        formData.append('tipipagamento', tipipagamento?tipipagamento:'');
        formData.append('emaildacercare', emaildacercare?emaildacercare:'');

        formData.append('istesserato', istesserato);
        axios.post(global.config.server+'/cercaAnagrafiche2', formData,
        {
            withCredentials: true,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Allow-Credentials': 'true',
              'Authorization' :'Bearer '+window.$t
            },
          },)
            .then((result) => {
                //console.log(result.data)
                setanagrafiche(result.data)
                dispatch(setanagricercate(result.data))
                setloading(false)
                setkeytorefresh(new Date())
            })
      }

      return (
        <div className="">
<div className="container mt-5">
<div className="row d-flex justify-content-center">
    <div className="col-md-10  sezione">
        <div className="card p-3  py-4">
            <div className="row">
            <h5 className="col-md-3">Ricerca anagrafiche</h5>
            <Bottone bootstrapsize={"col-md-3"}
            onClick={() => navigate('/annotatio-newanag/')}
            bootstrapstyle={"info"} label={<FontAwesomeIcon icon={faPlusCircle}  />} />
            </div>
            <div className="row g-3 mt-2">
                <div className="col-md-3">
                    <select 
                    id="form-field-name" 
                    className="form-control" 
                    name="valide" placeholder="Iscrizioni valide" value={valide} onChange={(e) => setvalide(e.target.value) }>
                        <option value="">--Tutte le iscrizioni--</option>
                        <option value="iscrizioniOggi">Oggi</option>
                        <option value="iscrizioniMese">In un mese</option>
                        <option value="iscrizioniAnno">In un anno</option>
                        <option value="valide">In corso</option>
                        <option value="scadute">Scadute</option>
                        <option value="scadenza7">Scadenza in 7 giorni</option>
                        <option value="scadenza30">Scadenza in 30 giorni</option>
                    </select>
                </div>
                <div className="col-md-6">
                <input
                    type="text" 
                    id="form-field-name" 
                    className="form-control" 
                    name="nomecognome" value={nomecognome} placeholder="Ricerca veloce per nome e/o cognome" onChange={(e)=>{setnomecognome(e.target.value);}}/>
                    <input
                    type="text" 
                    id="form-field-name" 
                    className="form-control" 
                    name="nomecognome" value={codicefiscale} placeholder="Codice Fiscale" onChange={(e)=>{setcodicefiscale(e.target.value);clearTimeout(timeout); timeout = setTimeout(splitdati(e.target.value), 2000);}}/>
                </div>
                <div className="col-md-3">
                    <button className="btn btn-secondary btn-block" onClick={handleSubmit}>Cerca</button>
                </div>
            </div>
            <div className="mt-3">
                <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" className="advanced">
                Ricerca avanzata <i className="fa fa-angle-down"></i>
                </a>
                <div className="collapse" id="collapseExample">
<div className="card card-body">
    <div className="row">
      <div className="col-6">
      <input type="email" 
            className="form-control" 
            name="emaildaceercare" value={emaildacercare} placeholder="Cerca una email" onChange={(e) => setemaildacercare(e.target.value)}
            />
      </div>
    </div>
    <div className="row">
        <div className="col-6">
            <input type="text" 
            className="form-control" 
            name="nome" value={nome} placeholder="Nome" onChange={(e) => setnome(e.target.value)}
            />
        </div>  
        <div className="col-6">
            <input type="text" 
            className="form-control" 
            name="cognome" value={cognome} placeholder="Cognome" onChange={(e) => setcognome(e.target.value)} />
        </div>
</div>
<div className="row">
        <div className="col-6">
            <label>Abbonamento valido da
                <input type="date" 
                className="form-control" 
                name="dataDa" value={dataDa} placeholder="Inizio validita"
                onChange={(e) => setdatada(e.target.value)} />
            </label>
        </div>
        <div className="col-6">
            <label>Abbonamento valido a
                <input type="date" 
                className="form-control" 
                name="dataA" value={dataA} placeholder="Fine validita"
                onChange={(e) => setdataA(e.target.value)} />
            </label>
        </div>
        <div className="col-6">
            <label>Abbonamento effettuato dal
                <input type="date" 
                className="form-control" 
                name="effettuatoDa" value={effettuatoDa} placeholder="Effettuato dal"
                onChange={(e) => seteffettuatoDa(e.target.value)} />
            </label>
        </div>
        <div className="col-6">
            <label>Abbonamento effettuato entro il
                <input type="date" 
                className="form-control" 
                name="effettuatoA" value={effettuatoA} placeholder="Effettuato entro il"
                onChange={(e) => seteffettuatoA(e.target.value)} />
            </label>
        </div>
        <div className="col-md-4">
			<select id="form-field-email" 
            className="form-control" 
            name="saldati" placeholder="Pagamenti saldati" value={saldati} onChange={(e) => setsaldati(e.target.value)}>
            <option value="0">--Tutti i pagamenti--</option>
            <option value="1">Saldati</option>
            <option value="2">Da saldare</option>
            </select>
        </div>
        <div className="col-md-4">
            <TipoAbbonamentoSelect selected={tipoabbonamento} update={(e) => settipoabbonamento(e.target.value)} />
		</div>

        <div className="col-md-4 ">
            <PeriodiSelect selected={periodo} update={(e) => setperiodo(e.target.value)} />
		</div>
        <div className="col-md-4 ">
            <TipiPagamentoSelect selected={tipipagamento} update={(e) => settipipagamento(e.target.value)} />
		</div>
</div>
</div>
</div>
            </div>
        </div>
    </div>
</div>
</div>
{
    loading?
    <div style={{ margin: 'auto',
        width: '50%'}}>
    <LoadingGif />
    </div>
    :null
}
<RiquadroRisRicAnagrafiche key={keytorefresh} email={email} idpalestra={palestraanagrafica.id} anagrafiche={anagrafiche?anagrafiche:anagricercate} link={props.link} setview={props.setview} childrenKey={keytorefresh} />
</div>
        );
  }