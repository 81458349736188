import React from "react";
import { useState } from "react";
import '../util/config'
import Popup from 'reactjs-popup';


export default function TipologieEserciziSelect(props) {
    const [immagine, setimmagine] =useState('');
    const [tipi, settipi] =useState([]);
    const [open, setopen]=useState(false);

    function filtra(filtro){
        console.log(props.tipologie)
        let tipit=props.tipologie.filter(tipot =>tipot.categoria.toUpperCase().indexOf(filtro.toUpperCase())>=0||tipot.descrizione.toUpperCase().indexOf(filtro.toUpperCase())>=0)
        settipi(tipit)
        console.log(tipi)
    }
    
    const Modal = () => (
        <Popup open={open} 
        closeOnDocumentClick
     onClose={() => setopen(false)}
        >
            Seleziona un esercizio:
            <input list="brow"  name={props.name} onChange={(e) => filtra(e.target.value)}/>
            <ul style={{height:400, overflow:"scroll"}}>
            {tipi.map(tipo=>(
                    <li key={tipo.idesercizio} name={props.name} value={tipo.idesercizio} onClick={() => {update(props.name, tipo.idesercizio); setopen(false)}}> {tipo.idesercizio===props.selected?true:false} {tipo.idesercizio} ({tipo.categoria}) {tipo.descrizione}</li>
                ))}
                </ul>
{/* <select className="form-control" required={false} name={props.name} onChange={update}>
                            <option value="0">--tipologie esercizi--</option>
                            {tipi.map(tipo=>(
                                <option key={tipo.idesercizio} value={tipo.idesercizio} selected={tipo.idesercizio===props.selected?true:false}>{tipo.categoria} {tipo.descrizione} </option>
                            ))}
                        </select> */}
                       
        </Popup>
      );

    function update(name, value){
        //console.log(name+" = "+value)
        alert(name+""+value)
        props.update(name, value);
        for (var i = 0; i < props.tipologie.length; i++) { 
            //console.log(props.tipologie[i].idesercizio+" - "+value)
            if ( Number(props.tipologie[i].idesercizio)===Number(value)){
                document.getElementById('img'+props.idschedaesercizi).src=props.tipologie[i].link
                return
            }
        }
    }

    return (
	    		<span>
                    {props.tipologie&&props.tipologie.length>0?
                    <div>
                        {props.tipologie.map(tipo=>(
                                Number(tipo.idesercizio)===Number(props.selected)?
                                    <span key={tipo.descrizione}>
                                    {tipo.descrizione}<br />{tipo.categoria}
                                    </span>
                                :null
                            ))}
                            
                       
                      
                         {props.tipologie.map(tipo=>
                         <span key={tipo.idesercizio}>
                                {
                                Number(tipo.idesercizio)===Number(props.selected)?
                                <img  src={tipo.link} style={{width:50}}/>
                                :null}
                                </span>
                            )
                        }
                        <img id={"img"+props.idschedaesercizi} src={immagine} style={{width:50}}/>
                       
                        <div className="col-md-12">
                        <button className="button" onClick={(e) => {e.preventDefault(); setopen(true)}}>Seleziona</button>
                        {Modal()}
                        </div>
                        </div>
                :null}
				</span>
	    );
	}