import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { cancellafattura, getFattura, modificafattura, sendEmailFattura } from "../DAO/fattureDAO";

import NuovaRiga from "./NuovaRiga";
import { getcorsi } from "../DAO/corsiDAO";
import { getlistini } from "../DAO/listiniDAO";
import { cercaAnagrafiche2, getAnagrafica } from "../DAO/anagraficaDAO";
import { getpagamentofattura } from "../DAO/pagamentiDAO";
import serverInfo from "../util/globals";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSearch, faEnvelope, faPlusCircle, faPrint, faEuroSign, faTrashAlt, faSave, faPlusSquare, faUserAlt, faAddressCard, faFileInvoice} from '@fortawesome/free-solid-svg-icons'
import {navigate, A} from 'hookrouter'
import { calcolaTotaleLordo } from "../util/nutrizionista/calcoli";
var timeout=0

export default function EditFattura (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const token = useSelector(state => state.gestoreUtente.token);
    const idfattura = props.idfatt;

    const[nome, setnome] = useState('')
    const[indirizzo, setindirizzo] = useState('')
    const[citta, setcitta] = useState('')
    const[cap, setcap] = useState('')
    const[cognome, setcognome] = useState('')
    const[codicefiscale, setcodicefiscale] = useState('')
    const[numero, setnumero] = useState('')
    const[data, setdata] = useState(new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2))
    const[anno, setanno] = useState(new Date().getFullYear())
    const [importoiva, setimportoiva] = useState(0);
    const [fedataexport, setfedataexport] = useState('')
   
    const [totlordo, settotlordo] = useState(0);
    const [prodotti, setprodotti] = useState([])
    const [listini, setlistini] = useState([])
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());
    const [reversecharge, setreversecharge] =useState(0);
    const [tesserasanitaria, settesserasanitaria] = useState('')
    const [datanascita, setdatanascita] = useState('')
    const [luogonascita, setluogonascita] = useState('')
    const [sesso, setsesso] = useState('')
    const [righe, setrighe] = useState([]);
    const [idanag, setidnang] = useState(0)
    const [telefono, settelefono] = useState('')
    const [emailanag, setemailanag] = useState('')
    const [idpagamento, setidpagamento] = useState(0);
    const[datapag, setdatapag] = useState(new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2))
    const [importopag, setimportopag] = useState(0);
    const [tipopag, settipopag] = useState('')

    function cancfatt(){
        async function cancellafatt(){
            try{
                if (window.confirm('Sei sicuro di voler cancellare questa fattura?')){
                    if (fedataexport){
                        if (window.confirm('La fattura è già stata esportata il '+fedataexport+'. Si è davvero sicuri di voler cancellare questa fattura?')){
                            const result = await cancellafattura(idfattura,email, palestra.palestra.id);
                            alert(result)
                            navigate('/annotatio-ric-fatt/')
                        }
                    }
                    else{
                        const result = await cancellafattura(idfattura,email, palestra.palestra.id);
                        alert(result)
                        navigate('/annotatio-ric-fatt/')
                    }
                }
            }
            catch(error){
                console.log(error)

            }
        }
        cancellafatt();
    }

    function modfatt(){
        async function modfattura(){
            try{
                if(!nome){
                    alert('Nome obbligatorio')
                    return;
                }
                if(!cognome){
                    alert('Cognome obbligatorio')
                    return;
                }
                if(!codicefiscale){
                    alert('Codice fiscale obbligatorio')
                    return;
                }
                if(!indirizzo){
                    alert('Indirizzo obbligatorio')
                    return;
                }
                if(!citta){
                    alert('Citta obbligatoria')
                    return;
                }
                if(!cap){
                    alert('CAP obbligatorio')
                    return;
                }
                if(!telefono){
                    alert('Telefono obbligatorio')
                    return;
                }
                if(!numero){
                    alert('numero fattura obbligatorio')
                    return;
                }
                if(!anno){
                    alert('Anno fattura obbligatorio')
                    return;
                }
                if(!data){
                    alert('Data fattura obbligatoria')
                    return;
                }
                if (!righe||righe.length===0){
                    alert('Inserire almeno una riga di dettaglio');
                    return;
                }
            if(!datapag||!importopag||importopag===0||!tipopag){
                if (window.confirm('Dati di pagamento mancanti, Continuare comunque?')){

                }
                else{
                    return;
                }
            }
            const result = await modificafattura(idfattura,
                nome, cognome, codicefiscale, indirizzo, citta, cap, sesso, datanascita, luogonascita, telefono, emailanag, numero, anno, data,0,righe, 
                reversecharge,  
                idpagamento, datapag, importopag, tipopag,
                palestra.palestra.id, email);
                if (result&&result.id>0){
                    alert('fattura modifica correttamente')
                }
            }
            catch(error){
                console.log(error)
            }
        }
        modfattura();
    }

    function aggiornarigadaprodotti(idprodotto, riga){
        console.log('aggiornarigadaprodotti ',idprodotto, riga)
        let prodottotemp={prezzo:0, descrizione:''}
        if (idprodotto>0){
            prodottotemp=prodotti.filter(prod=> prod.id===Number(idprodotto))[0]
            console.log(prodottotemp)
        }
        let rigatemp={idrigafattura:riga.idrigafattura, 
            quantita:riga.quantita, 
            costo:prodottotemp.prezzo, 
            descrizione:prodottotemp.descrizione,
            aliquotaiva:prodottotemp.aliquotaiva?prodottotemp.aliquotaiva:0,
            fenatura:prodottotemp.fenatura?prodottotemp.fenatura:'',
            idcorso:prodottotemp.id
        }
        console.log(rigatemp)
        let listarighetemp= righe.map(rigat =>  rigat.idrigafattura!==riga.idrigafattura?rigat:rigatemp )
        setrighe(listarighetemp)
        calcolaTotaleLordo(listarighetemp, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
    }

    function aggiornarigadalistini(idlistino, riga){
        console.log('aggiornarigadalistini ',idlistino, riga)
        let listinotemp={prezzo:0, descrizione:''}
        if (idlistino>0){
            listinotemp=listini.filter(listi=> listi.id===Number(idlistino))[0]
            console.log(listinotemp)
        }
        let rigatemp={idrigafattura:riga.idrigafattura, 
            quantita:riga.quantita, 
            costo:listinotemp.prezzo, 
            descrizione:listinotemp.descrizione,
            aliquotaiva:0,
            fenatura:'',
            idcorso:listinotemp.id
        }
        console.log(rigatemp)
        let listarighetemp= righe.map(rigat =>  rigat.idrigafattura!==riga.idrigafattura?rigat:rigatemp )
        setrighe(listarighetemp)
        calcolaTotaleLordo(listarighetemp, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
    }

    function updateRiga(e,idriga) {
		var righetemp=righe;
		const index=righetemp.findIndex(rigat => rigat.idrigafattura === idriga);
		righetemp[index][e.target.name]=e.target.value
		setrighe(righetemp);
		calcolaTotaleLordo(righetemp, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
	}

    
    function aggiungiRiga(){
		//var righetemp=righe;
		var nuovariga={idrigafattura:'_' + Math.random().toString(36).substr(2, 9),quantita:1, costo:0, descrizione: ""}
        let righetemp=righe.map(riga => riga.idrigafattura!==nuovariga.idrigafattura?riga:nuovariga)
		righetemp.push(nuovariga);
		setrighe( righetemp );
	}
	 
	function eliminaRiga(event,idriga){
		var righetemp=righe;
		const index=righetemp.findIndex(rigat => rigat.idrigafattura === idriga);
		righetemp.splice(index,1)
		setrighe( righetemp );
        setkeytorefresh(new Date().getTime())
		calcolaTotaleLordo(righetemp, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
	}

    function caricaListini(){
        async function listini(){
            const result=await getlistini(email, palestra.palestra.id);
            console.log(result)
            setlistini(result);
        }
        listini();
    }

    function caricaProdotti(){
        async function prodotti(){
            const result=await getcorsi(email, palestra.palestra.id, '');
            
            let corsitemp=[]
            result.map(tipocorso =>
                tipocorso.corsi.map(corso => {
                    corsitemp.push(corso)
                    }
                    )
                )
            setprodotti(corsitemp);
        }
        prodotti();
    }

    function stampafatt(){
        var win = window.open(serverInfo.name+'/stampaFattura?id='+idfattura, '_blank');
		win.focus();
    }

    function emailfatt(){
        async function getfatt(){
            const result = await sendEmailFattura(idfattura, email, palestra.palestra.id)
            alert(result.message)
            
        }
        if (window.confirm('Inviare email fattura a '+emailanag+'?')){
            getfatt()
        }
    }

    function caricafattura(){
        async function getfatt(){
            const fattura = await getFattura(idfattura);
            console.log(fattura)
            setanno(fattura.anno);
            setdata(fattura.data);
            setimportoiva(fattura.importolordototale-fattura.importonettototale)
            setreversecharge(fattura.reversecharge)
            setnumero(fattura.numero);
            setrighe(fattura.righe);
            setfedataexport(fattura.fedataexport);
            settotlordo(fattura.importolordototale);
            if(fattura.idutentedestinatario){
                const result = await getAnagrafica(email, '00', palestra.palestra.id,fattura.idutentedestinatario, token);
                console.log(result,'idutentedestinatario')
                setidnang(result.id)
                setcognome(result.cognome)
                setnome(result.nome)
                setcodicefiscale(result.codicefiscale)
                setdatanascita(result.datanascita)
                setsesso(result.sesso)
                setluogonascita(result.luogonascita)
                setindirizzo(result.indirizzo)
                setcap(result.cap)
                setcitta(result.citta)
                settelefono(result.telefono)
                setemailanag(result.email)
            }
            calcolaTotaleLordo(fattura.righe, palestra.palestra.id, setimportoiva, settotlordo, setimportopag);
            const pagamenti = await getpagamentofattura(idfattura, email, palestra.palestra.id);
            if (pagamenti&&Array.isArray(pagamenti)&&pagamenti.length>0){
                console.log(pagamenti[0])
                setidpagamento(pagamenti[0].id)
                setdatapag(pagamenti[0].dataPagamento);
                setimportopag(pagamenti[0].importo)
                settipopag(pagamenti[0].tipo)
            }
            
        }
        getfatt();
    }

    useEffect(() => {
        caricaListini();
        caricaProdotti();
        caricafattura();
    },[email,palestra])


    return (
    		<div key={keytorefresh} className="container-fluid">
                <div className="row">
                    <div className="col-md-3"> <h4>Dettaglio fattura {numero}/{anno}</h4></div>
                    <div className="col-md-3">
                        <A href="/annotatio-ric-fatt/" style={{padding:5, margin:5}} className="button">
                            <FontAwesomeIcon icon={faSearch} /> Ricerca fatture</A>
                            </div>
                            <div className="col-md-3">
                            <A href={"/annotatio-ins-fatt/"+idanag} style={{padding:5, margin:5}} className="button">
                    <FontAwesomeIcon icon={faPlusCircle} /> Inserisci nuova fattura di {nome} {cognome}</A>
                    </div>
                    <div className="col-md-3">
                <A href="/annotatio-ins-fatt/" style={{padding:5, margin:5}} className="button">
                    <FontAwesomeIcon icon={faPlusCircle} /> Inserisci nuova fattura</A>
                    </div>
                </div>
                <div className="row sezione">
                    <div className="col-md-2"><h4> <FontAwesomeIcon icon={faUserAlt} /> Anagrafica</h4></div>
                    <div className="col-md-10">
                    <div className="row">
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Nome</label>
                        <input type="text" 
                        className="form-control" style={{ textTransform: 'capitalize' }}
                        placeholder="NOME" name="nome" value={nome} onChange={(e) =>setnome(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Cognome</label>
                        <input type="text" 
                        className="form-control" style={{ textTransform: 'capitalize' }}
                        placeholder="COGNOME" name="cognome" value={cognome} onChange={(e) =>setcognome(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Cod. Fiscale</label>
                        <input type="text" 
                        className="form-control" 
                        placeholder="CODICE FISCALE" name="codicefiscale" value={codicefiscale} onChange={(e) =>setcodicefiscale(e.target.value)} />
                    </div>

                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Data nascita</label>
                        <input type="date" 
                        className="form-control"
                        placeholder="DATA NASCITA" name="datanascita" value={datanascita} onChange={(e) =>setdatanascita(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Luogo nascita</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="LUOGO NASCITA" name="luogonascita" value={luogonascita} onChange={(e) =>setluogonascita(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4">
                    <label for="inputPassword4">Sesso</label>
                        <select name="sesso" value={sesso} onChange={(e) =>setsesso(e.target.value)} className="form-control form-control-lg">
                            <option value=''>--seleziona sesso--</option>
                            <option value='M'>M</option>
                            <option value='F'>F</option>
                        </select>
                    </div>
                </div>
                </div>
                </div> {/*  FINE ANAGRAFICA */}
                <div className="row sezione">
                    <div className="col-md-2"><h4><FontAwesomeIcon icon={faAddressCard} /> Indirizzo e recapiti</h4></div>
                    <div className="col-md-10">
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Indirizzo</label>
                            <input type="text" 
                            className="form-control"
                            placeholder="INDIRIZZO" name="indirizzo" value={indirizzo} onChange={(e) =>setindirizzo(e.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Citta</label>
                            <input type="text" 
                            className="form-control"
                            placeholder="CITTA" name="citta" value={citta} onChange={(e) =>setcitta(e.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Cap</label>
                            <input type="text" 
                            className="form-control"
                            placeholder="CAP" name="cap" value={cap} onChange={(e) =>setcap(e.target.value)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Telefono</label>
                            <input type="phone" 
                            className="form-control"
                            placeholder="TELEFONO" name="telefono" value={telefono} onChange={(e) =>settelefono(e.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Email</label>
                            <input type="email" 
                            className="form-control"
                            placeholder="EMAIL" name="email" value={emailanag} onChange={(e) =>setemailanag(e.target.value)} />
                        </div>
                    </div>
                </div>
                </div> {/*  FINE INDIRIZZO */}
        <div className="row sezione">
            <div className="col-md-2"><h4><FontAwesomeIcon icon={faFileInvoice} /> Dati fattura</h4></div>
                <div className="col-md-10">
                    <div className="row">
                    <div className="form-group col-md-2">
                        <label for="inputPassword4">Numero</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="NUMERO" name="numero" value={numero} onChange={(e) =>setnumero(e.target.value)} />
                    </div>
                    <div className="form-group col-md-2">
                        <label for="inputPassword4">Anno</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="ANNO" name="anno" value={anno} onChange={(e) =>setanno(e.target.value)} />
                    </div>
                    <div className="form-group col-md-2">
                        <label for="inputPassword4">Data</label>
                        <input type="date" 
                        className="form-control"
                        placeholder="Data" name="data" value={data} onChange={(e) =>setdata(e.target.value)} />
                    </div>
                    <div className="form-group col-md-2">
                        <label for="inputPassword4">Data Export </label>
                        <input type="text" disabled={true} readOnly={true}
                        className="form-control"
                        placeholder="Data" name="data" value={fedataexport}/>
                    </div>
                    <div className="form-group col-md-2">
                        <label for="inputPassword4">Reverse Charge</label>
                        <input type="checkbox" placeholder="Reverse charge" checked={reversecharge===1} name="reversecharge" value={1} onChange={(e) =>setreversecharge(e.target.checked?1:0)} />
                    </div>
                </div>
            </div>
        </div>
                <h3>Righe fattura</h3>
                <table  className="table table-bordered table-primary" style={{width:'100%'}}>
                    <thead>
                        <tr>
                            <th>Descrizione</th>
                            <th>Q.ta</th>
                            <th>&nbsp;&nbsp;Costo&nbsp;&nbsp;</th>
                            <th>Tot. servizio</th>
                            <th>Aliquta Iva</th>
                            <th>Importo IVA</th>
                            <th>Natura (F.E.)</th>
                            <th>Elimina</th>
                        </tr>
                    </thead>
                    <tbody>
                    {righe.map( riga => (
                        <NuovaRiga key={riga.idrigafattura} updateRiga={updateRiga} riga={riga} eliminaRiga={eliminaRiga} 
                        listini={listini} prodotti={prodotti} 
                        aggiornarigadaprodotti={aggiornarigadaprodotti} 
                        aggiornarigadalistini={aggiornarigadalistini} />
                    ))}
                    <tr>
                        <td><button type="button" class="btn btn-secondary" onClick={aggiungiRiga}> 
                <FontAwesomeIcon icon={faPlusSquare} /> Aggiungi riga
                </button></td>
                        <td colspan={2} style={{textAlign:'right'}}>Importo netto totale</td>
                        <td style={{textAlign:'right'}}>{totlordo-importoiva}</td>
                        <td style={{textAlign:'right'}}>Importo totale IVA</td>
                        <td style={{textAlign:'right'}}>{importoiva}</td>
                        <td style={{textAlign:'right'}}>Totale Fattura</td>
                        <td style={{textAlign:'right'}}>{totlordo}</td>
                    </tr>
                   
                    </tbody>
                </table>                

        <div className="row sezione">
            <div className="col-md-2"><h4><FontAwesomeIcon icon={faEuroSign} /> Dati pagamento</h4></div>
                <div className="col-md-10">
                <div className="row">
                <div className="form-group col-md-3">
                        <label for="inputPassword4">Data</label>
                        <input type="date" 
                        className="form-control"
                        placeholder="Data" name="datapag" value={datapag} onChange={(e) =>setdatapag(e.target.value)} />
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputPassword4">Tipo</label>
                        <select className="form-control" name="tipopag" value={tipopag} onChange={(e) =>settipopag(e.target.value)}>
                        <option value="">--Seleziona un metodo di pagamento--</option>
                        <option value="carta">carta/bancomat</option>
                        <option value="contanti">contanti</option>
                        <option value="assegno">assegno</option>
                        <option value="bonifico">bonifico</option>
                        </select>
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputPassword4">Importo</label>
                        <input type="number" 
                        className="form-control"
                        placeholder="Importo" name="importopag" value={importopag} onChange={(e) =>setimportopag(e.target.value)} />

                </div>
            </div>
            </div>
        </div>
                <div className="row">
                    <button type="button" class="btn btn-success" onClick={modfatt}><FontAwesomeIcon icon={faSave} /> SALVA</button>
                    
                    <button type="button" class="btn btn-primary" onClick={stampafatt}> <FontAwesomeIcon icon={faPrint} /> STAMPA</button>
                    <button type="button" class="btn btn-secondary" onClick={emailfatt}> <FontAwesomeIcon icon={faEnvelope} />INVIA PER EMAIL</button>
                    <button type="button" class="btn btn-danger" onClick={cancfatt}><FontAwesomeIcon icon={faTrashAlt} /> ELIMINA FATTURA</button>
                </div>
            </div>
    );
}