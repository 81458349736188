import { getPalestra, getPalestraByHost } from "../DAO/palestraDAO";

getUserIP(testalo)
setTimeout(() => { console.log('Aspetta 2 secondi!'); }, 2000);
var ipa="http://localhost:8080"

export const serverInfo = {
  name: localStorage.getItem('server')?
  ipa+'/wp':'https://be.annotatio.online/wp',
  namesec: localStorage.getItem('server')?
  ipa
  :'https://be.annotatio.online',
  domain: window&&window.location&&window.location.hostname&&(window.location.hostname.includes('localhost')||window.location.hostname.includes('192.'))?'annotatio.online':window.location.hostname

}

function testalo(nuovoip){
  ipa=nuovoip
}

function getUserIP(onNewIP) { //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  var myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
  var pc = new myPeerConnection({
      iceServers: []
    }),
    noop = function() {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
    key;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }
  //onNewIP()
  //create a bogus data channel
  pc.createDataChannel("");

  // create offer and set local description
  pc.createOffer().then(function(sdp) {
    sdp.sdp.split('\n').forEach(function(line) {
      if (line.indexOf('candidate') < 0) return;
      line.match(ipRegex).forEach(iterateIP);
    });

    pc.setLocalDescription(sdp, noop, noop);
  }).catch(function(reason) {
    // An error occurred, so handle the failure to connect
  });

  //listen for candidate events
  pc.onicecandidate = function(ice) {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}


  export default serverInfo;

  export const palestraDefault = {
    "id":0,
}

  export const versione = {
    androidVersion:'1.0.0', //nel file reactnativePhysico/android/app/build.gradle ==> android { defaultConfig { versionName 
    androidVersionCode:'1', //nel file reactnativePhysico/android/app/build.gradle ==> android { defaultConfig { versionName
    iosVersion:'1.0.0' // reactnativePhysico.xcodeproj ==> general ==> target ==> reactnativePhysico ==> Identity ==> version
  }

  export function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
  
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
  
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  export async function getPalestraFromUrl(token, idpalestraselezionata){
    let palestratemp={}
    if(global.config.domain.indexOf('localhost')>=0
    ||(idpalestraselezionata&&idpalestraselezionata>0)){
      if (idpalestraselezionata>0){
        palestratemp=await getPalestra(idpalestraselezionata);
      }
      else if (palestraDefault&&palestraDefault.id&&palestraDefault.id>0){
        palestratemp=await getPalestra(palestraDefault.id);
      }
    }
    else{
        palestratemp=await getPalestraByHost(global.config.domain, token);
      }
      return palestratemp
  }

  export async function getPalestraSocioFromUrlEmail(palestreassociate, idpalestraselezionata, token){
    console.log('getPalestraSocioFromUrlEmail')
    let palestratemp={}
    if(global.config.domain.indexOf('localhost')>=0
    ||(idpalestraselezionata&&idpalestraselezionata>0)){
        if (idpalestraselezionata&&idpalestraselezionata>0){
          palestratemp={id:idpalestraselezionata}
        }
        else if (palestraDefault&&palestraDefault.id){
          palestratemp=palestraDefault;
        }
      }
      else{
        palestratemp=await getPalestraByHost(global.config.domain, token);
      }
      if (!palestratemp||!palestratemp.id){
        palestratemp=global.localstorage.palestrasocioSelezionata?global.localstorage.palestrasocioSelezionata.palestra:""
      }
      return palestreassociate.filter(paltemp => paltemp.palestra.id===Number(palestratemp.id))[0]
  }


  async function getLocalIPAddress(callback) {
    const RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    const pc = new RTCPeerConnection({ iceServers: [] });
    const noop = () => {};
    
    pc.createDataChannel('');
    
    pc.createOffer()
      .then(offer => pc.setLocalDescription(offer))
      .catch(noop);
    
    pc.onicecandidate = function(e) {
      if (!e.candidate) {
        pc.close();
        return;
      }
      
      const ipRegex = /(?:\d{1,3}\.){3}\d{1,3}/;
      const ipAddress = ipRegex.exec(e.candidate.candidate)[0];
      console.log('getLocalIPAddress '+ipAddress)
      // if (typeof callback === 'function') {
      //   callback(ipAddress);
      // }
      ipa=ipAddress
      
      pc.close();
    };
  }
  