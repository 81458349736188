import React, { useState } from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import {  Button, Form, } from "react-bootstrap";
import CorsiSelect from "../archivi/corsi/CorsiSelect";
import UtentiFilterSelect from "../anagrafica/UtentiFilterSelect";
import {useDispatch, useSelector} from 'react-redux'
import serverInfo from "../util/globals";
import { navigate } from 'hookrouter'
import SelectTipologiaCorso from "../archivi/tipologiaCorso/SelectTipologiaCorso";
import { useEffect } from "react";
import { getcorsi } from "../DAO/corsiDAO";
import { settipiprodotto } from "../STORE/reducers/gestoreTipiProdotto";

export default function DettaglioSchedulazione() {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
	const email = useSelector(state => state.gestoreUtente.emailloggato);
	const calendario  =  useSelector(state =>  state.gestoreSchedulazione.calendario);
	const schedulazione  =  useSelector(state =>  state.gestoreSchedulazione.schedulazione);
	const oraSelezionata  =  useSelector(state =>  state.gestoreSchedulazione.oraSelezionata);
	const togglePopup  =  useSelector(state =>  state.gestoreSchedulazione.togglePopup);

	const [messaggio, setmessaggio] = useState('')
	const [id, setid] = useState(schedulazione.id)
	const [anagrafica, setanagrafica] = useState()
	const [nomeLibero, setnomeLibero] = useState()
	const [fisso, setfisso] = useState()
	const [prenotazioni, setprenotazioni] = useState([])

	const [durata, setdurata] = useState(schedulazione.durata)
	const [numMaxPrenotazioni, setnumMaxPrenotazioni] = useState(schedulazione.nummaxprenotazioni)
	const [corso, setcorso] = useState(schedulazione.corso)
	const [data, setdata] = useState(schedulazione.data)
	const [ora, setora] = useState(schedulazione.ora)

	const [tipoPrenotazione, settipoPrenotazione] = useState(schedulazione.tipoPrenotazione)
	const [duratagiornaliera, setduratagiornaliera] = useState(0)
	const [listatipi, setlistatipi] = useState([])
	const [tipocorso, settipocorso] = useState({id:schedulazione.idtipocorso})
	const [listaCorsi, setlistaCorsi] = useState([])
	const [visible, setvisible] = useState(false)

	const dispatch = useDispatch()

	function inviaMessaggio(){
        if (messaggio.trim()===''){
            alert("Messaggio obbligatorio");
            return;
        }
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra', palestra.id);
        formData.append('msg', messaggio);
		formData.append('idschedulazione',id);
        axios.post(global.config.server+'/inviaMessaggioSchedulazione', formData)
        .then((result) => {
            alert(result.data)
        });
	  }

	function annullaPrenotazione(e) {
		if (window.confirm("Vuoi annullare la prenotazione selezionata?")){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',email);
			formData.append('lic','000');
			formData.append('palestra',palestra.id);
			formData.append('idPrenotazione',e.target.id);
			formData.append('calendario',calendario);
			axios.post(serverInfo.namesec+'/abb/annullaPrenotazione',formData,
			{
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+window.$t
				},
			  },
			)
			.then((result)=>{
				alert(result.data)
				setvisible(false)
				refreshPrenotazioni()
			})
			.catch(err => {
				if (err.response){
					alert(err.response.data.message);
				}
				else{
					alert(err)
				}
			})
		}
	}

	function annullaDefinitivamente(e) {
		if (window.confirm("Vuoi annullare la prenotazione fissa selezionata?")){
			const formData = new FormData();
			formData.append('idPrenotazione',e.target.id);
			axios.post(global.config.server+'/annullaDefinitivamente',formData)
			.then((result)=>{
				alert(result.data)
				setvisible(false)
				refreshPrenotazioni()
			})
			.catch(err => {
				if (err.response){
					alert(err.response.data.message);
				}
				else{
					alert(err)
				}
			})
		}
	}

	function eliminaSchedulazione(e) {
		if (window.confirm("Vuoi eliminare la schedulazione selezionata?")){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',email);
			formData.append('lic','000');
			formData.append('palestra',palestra.id);
			formData.append('idSchedulazione',id);
			formData.append('calendario',calendario);
			axios.post(global.config.server+'/eliminaSchedulazione',formData)
			.then((result)=>{
				alert(result.data)
				navigate('/annotatio-prods/')
			})
			.catch(err => {
				if (err.response){
					alert(err.response.data.message);
				}
				else{
					alert(err)
				}
			})
		}
	}

	function aggiungiPrenotazione(){
		if (!anagrafica&&!nomeLibero){
			alert('Specificare una anagrafica oppure un nome libero')
			return
		}
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
		formData.append('idUtente',anagrafica?anagrafica:'');
		formData.append('idAnagrafica',anagrafica?anagrafica:'');
		formData.append('nomelibero',nomeLibero?nomeLibero:'');
		formData.append('idSchedulazione',id);
		formData.append('fisso',fisso&&(fisso===true||fisso===1||fisso==='on')?1:0);
		formData.append('calendario',calendario);
		axios.post(global.config.server+'/inserisciPrenotazione',formData)
	      .then((result) => {
				
				if (result.data.conferma){
					if (window.confirm('Si è verificato il seguente problema: '+result.data.message+'. Continuare?')){
						axios.post(global.config.server+'/confermaPrenotazione',formData)
						.then((result2) => {
							alert(result2.data.message)
							refreshPrenotazioni()
						}).catch(err => {
							if (err.response){
								alert(err.response.data.message);
							}
							else{
								alert(err)
							}
						})
					}
				}
				else{
					alert(result.data.message)
					refreshPrenotazioni()
				}
	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
	
	  }

	function refreshPrenotazioni(){
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
		axios.post(global.config.server+'/getPrenotazioni/'+schedulazione.id,formData)
		.then((result)=>{
			setprenotazioni(result.data)
			console.log(result.data)
		})
		.catch(err => {
			if (err.response){
				alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		})
	}

	function aggiornatipo(idtipo){
		settipocorso(idtipo)
		setcorso('')
	}

    function handleSubmit(event) {
		event.preventDefault();
	   
		const formData = new FormData();
	    formData.append('duratagiornaliera', duratagiornaliera);
		formData.append('durata', durata);
	    formData.append('numMaxPrenotazioni', numMaxPrenotazioni);
		formData.append('corso', corso);
		formData.append('data', data);
		formData.append('ora', ora);
		formData.append('id', id);
		formData.append('tipoPrenotazione', tipoPrenotazione);
		formData.append('website', global.config.domain);
		formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
		formData.append('calendario',calendario);
		formData.append('idtipocorso',tipocorso&&tipocorso.id?tipocorso.id:'');
		axios.post(global.config.server+'/aggiornaSchedulazione',formData)
	      .then((result) => {
		        setmessaggio(result.data);
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
      }

	  function caricacorsi(){
		async function caricac(){
			const result=await getcorsi(email, palestra.id, '')
			//console.log(result)
			setlistatipi(result);
			dispatch(settipiprodotto(result))
			setlistaCorsi(result);
		}
		caricac()
	  }

	  useEffect(() => {
		caricacorsi()
		refreshPrenotazioni()
	  },[])
      

	    return (
			<div>
				<h2>Schedulazione
				<Button variant="secondary" onClick={() => navigate('/annotatio-prods/')}>
					Torna al calendario
				</Button>
				</h2>
				<a name="schedulazione" />
				
				<form>
					<div className="row sezione">
						<div className="col-md">
						<Form.Group controlId="Giorno">
							<Form.Label>Giorno</Form.Label>
							<Form.Control type="date" placeholder="giorno" dateFormat="DD/MM/YYYY"
							name="data" value={data} required={true} onChange={(e) => setdata(e.target.value)} />
						</Form.Group>
						</div>
						<div className="col-md">
						<Form.Group controlId="importo">
							<Form.Label>Ora</Form.Label>
							<Form.Control type="time" placeholder="ora" 
							name="ora" value={ora} required={true} onChange={(e) => setora(e.target.value)} 
							min={6} max={22}/>
						</Form.Group>
						</div>
					</div>
					<div className="row sezione">
					<div className="col-md">
							<Form.Group controlId="tipicorso">
								<Form.Label>Tipologie</Form.Label>
								<SelectTipologiaCorso updateTipo={aggiornatipo} corsi={listatipi} selected={tipocorso} />
							</Form.Group>
						</div>
						<div className="col-md"><br/>--oppure--</div>
						<div className="col-md">
						<Form.Group controlId="datapagamento">
							<Form.Label>Seleziona un Prodotto</Form.Label>
							<CorsiSelect onChange={(e) => {settipocorso(null); setcorso(e.target.value)}} selected={corso} corsi={listaCorsi}/>
						</Form.Group>
						
						</div>
						</div>
					<div className="row sezione">
					<div className="col-md">
                    <Form.Group controlId="importo">
						<Form.Label>Durata</Form.Label>
						<Form.Control type="number" placeholder="Durata" 
						name="durata" value={durata} required={true} onChange={(e) => setdurata(e.target.value)} 
                        min={5}/>
						<label>Tutto il giorno <input type={"checkbox"} name={"duratagiornaliera"} onChange={(e) => setduratagiornaliera(e.target.value)}  /></label>
					</Form.Group>
					</div>
					<div className="col-md">
					<Form.Group>
						<Form.Label>Max prenotazioni</Form.Label>
						<Form.Control type="number" placeholder="Max Prenotazioni" 
						name="numMaxPrenotazioni" value={numMaxPrenotazioni} required={true} onChange={(e) => setnumMaxPrenotazioni(e.target.value)} 
                        />
					</Form.Group>
					</div>
					<div className="col-md">
					<Form.Group>
						<Form.Label>Tipo prenotazione</Form.Label>
						<select className="form-control"  defaultValue={tipoPrenotazione} name="tipoPrenotazione" required={true} onChange={(e) => settipoPrenotazione(e.target.value)}>
							<option value="">--tipo prenotazione--</option>
							<option value="Abbonamento">Abbonamento</option>
							<option value="Free">Free</option>
							<option value="Pagamento Anticipato">Pagamento Anticipato</option>
							<option value="Pagamento Posticipato o in loco">Pagamento Posticipato</option>
						</select>
					</Form.Group>
					</div>
					</div>
					<div className="row">
					<div className="col-md">
					<Button variant="primary" onClick={handleSubmit}>
						Salva Schedulazione
					</Button>
					<Button variant="danger" onClick={eliminaSchedulazione}>
						Elimina Schedulazione
					</Button>
					</div>
					</div>
					<hr />
					<div className="row">
						<h4>Inserimento prenotazioni</h4>
					</div>
					<div className="row sezione">
						<div className="col-md">
							<UtentiFilterSelect onChange={(e) => setanagrafica(e.target.value)}/>
						
						{anagrafica&&<Form.Group controlId="importo">
							<Form.Label>Fisso
							<Form.Control type="checkbox"
							name="fisso" onChange={(e) => setfisso(e.target.value)} 
							/>
							</Form.Label>
						</Form.Group>}
						</div>
						<div className="col-md">
						<label>Oppure inserisci un nome liberamente</label>
								<input className="form-control" type="text" name="nomeLibero" value={nomeLibero} onChange={(e) => setnomeLibero(e.target.value)} />
							
						</div>
					</div>
					<div className="row">
					<div className="col-12">
						<Button variant="info" onClick={aggiungiPrenotazione}>
							Aggiungi
						</Button>
						</div>
					</div>
				</form>
				<hr />
				<div className="row">
						<h4>Prenotazioni</h4>
					</div>
<table className="table" style={{width:'100%'}}>
	<thead>
		<tr>
			<th>N.</th><th>Nome</th><th>Abb.</th><th>Fisso</th><th>Data prenotazione</th><th>Modalità</th><th>Data annullamento</th>
		</tr>
	</thead>
	<tbody>

	
					{
					prenotazioni
					&&Array.isArray(prenotazioni)
					&&prenotazioni.length>0?
					prenotazioni.filter(prenotazione=>prenotazione.dataAnnullamento===null||prenotazione.dataAnnullamento==="").map((prenotazione,i)=>(
						<tr>
							<td>{(i+1)>numMaxPrenotazioni? "RISERVA "+(i+1-numMaxPrenotazioni)+" ":(i+1)}){" "}</td>
							<td>{prenotazione.nomecognome}
							{prenotazione.corso&&<p>{prenotazione.corso}</p>}
							</td>
							<td>{prenotazione.listino}</td>
							<td>{prenotazione.fisso?"(FISSO)":null} </td>
							<td>{prenotazione.dataOraPrenotazione}</td>
							<td>{prenotazione.modalitaPrenotazione}</td>
							<td>
								<Button id={prenotazione.id} onClick={annullaPrenotazione} variant="danger" >Annulla prenotazione</Button>
								{prenotazione.fisso&&<Button id={prenotazione.id} onClick={annullaDefinitivamente} variant="danger" >Annulla prenotazione FISSA</Button>}
							</td>
						</tr>
))
					
:null}

					{
					prenotazioni
					&&Array.isArray(prenotazioni)
					&&prenotazioni.length>0?
					prenotazioni.filter(prenotazione=>prenotazione.dataAnnullamento).map(prenotazione=>(
						<tr>
							<td>NC</td>
							<td>{prenotazione.nomecognome}
							{prenotazione.corso&&<p>{prenotazione.corso}</p>}
							</td>
							<td>{prenotazione.listino}</td>
							<td>{prenotazione.fisso?"(FISSO)":null} </td>
							<td>{prenotazione.dataOraPrenotazione}</td>
							<td>{prenotazione.modalitaPrenotazione}</td>
							<td>{prenotazione.dataAnnullamento}</td>
						</tr>
					))
					:""
					}
					
					</tbody>
</table>
<div className="row">
						<h4>Invia messaggio (WhatsApp)</h4>
					</div>
<div class="form-group sezione">
Si informa la gentile clientela che la schedulazione di {schedulazione.descrizione} del {data} ore {ora},
            <textarea class="form-control" 
            name="messaggio" id="Messaggio" 
            value={messaggio}
            onChange={(e) => setmessaggio(e.target.value)}></textarea>
             <button class="btn btn-primary" onClick={inviaMessaggio}>Invia messaggio </button>
        </div>
				<Button variant="secondary" onClick={() => navigate('/annotatio-prods/')}>
					Torna al calendario
				</Button>
			</div>
	    );
}