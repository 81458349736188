import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import TipologieAbbonamentoSelect from "../tipologiaAbbonamento/TipologieAbbonamentoSelect";
import PeriodoSelect from "../periodi/PeriodoSelect";
import { connect } from "react-redux";

class ListinoInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		pagaIscrizione: this.props.selected.pagaIscrizione,
	    		prezzo: this.props.selected.prezzo,
	    		periodo: this.props.selected.periodo,
	    		tipoAbbonamento: this.props.selected.tipoAbbonamento,
				pubblico: this.props.selected.pubblico,
				visible: false,
				listaTipologie:this.props.listaTipologie,
	    		messaggio:null
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.updateTipo = this.updateTipo.bind(this);
	    this.updatePeriodo = this.updatePeriodo.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	updateTipo(tipoSelezionato) {
		var tipoAbbonamento = {...this.state.tipoAbbonamento}
		tipoAbbonamento = tipoSelezionato;
		this.setState({tipoAbbonamento, visible: true})
	  }
	
	updatePeriodo(periodoSelezionato) {
		var periodo = {...this.state.periodo}
		periodo.id = periodoSelezionato;
		this.setState({periodo, visible: true})
	  }

	  handleSubmit(event) {
		event.preventDefault();
	    const { id,pagaIscrizione, prezzo, periodo, tipoAbbonamento, pubblico} = this.state;
	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('pagaIscrizione', pagaIscrizione);
		formData.append('prezzo', prezzo);
		formData.append('periodo', periodo.id);
		formData.append('tipoAbbonamento', tipoAbbonamento.id);
		formData.append('website', global.config.domain);
		formData.append('pubblico', pubblico);
        formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/aggiornaListinoRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      }).catch(err => {
			console.log(err)
			if (err.response){
			  alert(err.response.data.message);
			}
		  });
	  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare il listino selezionato?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/eliminaListinoRest/'+this.state.id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        this.props.refresh();
			          this.setState({ visible: false });
			        }).catch(err => {
						if (err.response){
						  alert(err.response.data.message);
						}
						else{
						  alert(err.response);
						}
					  });
			}
	  }

	render() {
		var style1={
				color:"white"
		}

	    return (
			<form className="form-horizontal">
				<div className="row">
					<span>{this.state.messaggio}</span>
					<div className="col">
						<label className="control-label">SKU &nbsp; </label>
						{this.state.id}
					</div>
					<div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
						<TipologieAbbonamentoSelect listaTipologie={this.props.listaTipologie} 
						selected={this.state.tipoAbbonamento.id}
						updateTipo={this.updateTipo} 
						email={this.props.email} />
					</div>
					<div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
						<PeriodoSelect listaPeriodi={this.props.listaPeriodi} 
						selected={this.state.periodo?this.state.periodo.id:null} 
						updatePeriodo={this.updatePeriodo} 
						email={this.props.email} />
					</div>
					<div className="col">
						<label className="control-label">Prezzo</label>
						<input className="form-control" type="number" onChange={this.update} name="prezzo" step="0.5" value={this.state.prezzo} />
					</div>
					<div className="col">
						<label className="control-label">Pag.Iscrizione</label>
						<select className="form-control" name="pagaIscrizione" value={this.state.pagaIscrizione&&(this.state.pagaIscrizione===1||this.state.pagaIscrizione=="1")?"1":"0"} onChange={this.update}>
							<option value="1">Si</option>
							<option value="0">No</option>
						</select>
					</div>
					<div className="col">
						<label className="control-label">Pubblico</label>
						<select className="form-control" name="pubblico" value={this.state.pubblico&&(this.state.pubblico===1||this.state.pubblico==="1"||this.state.pubblico===true)?"1":"0"} onChange={this.update}>
							<option value="1">Si</option>
							<option value="0">No</option>
						</select>
					</div>
					{this.state.visible?
							<div className="col">
							<a type="button" className="btn btn-primary" onClick={this.handleSubmit} style={style1}>Salva modifiche</a>
							</div>
						:null}
					<div className="col">
						<a className="btn btn-danger" style={{color:"white"}} onClick={this.elimina}>Elimina</a>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12">
					<hr style={{borderTop: '8px solid #bbb', borderRadius: '5px', marginBottom:'25px'}} />
					</div>
				</div>
			</form>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(ListinoInput)