import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function SelectTipologiaCorso({corsi, selected, updateTipo}){

	const tipicorso = useSelector(state => state.gestoreTipiProdotto.tipi);

	function aggiornaSelezionato(e){
		
		if ((e.target.value)===''){
			updateTipo(null)
		}
		else{
		 updateTipo(tipicorso[e.target.selectedIndex-1]);
		}
	 }

	 useEffect(() =>{
		console.log(tipicorso,'tipicorso')
	 })

	    return (
	    	<div className="col">
			<select className="form-control" onChange={aggiornaSelezionato} name="tipoCorso" value={selected?selected.id:''}>
				<option key={-1} value="">--seleziona tipo--</option>
				{
					tipicorso.map((item,index) =>(
					<option key={index} value={item.id} >{item.nome}</option>
					)
	    			)
	    		}
			</select>
			</div>
	    );
	}