import React from "react";
import axios from 'axios';
import '../../util/config'
import Swal from "sweetalert2";
import TipoAbbTipoCorsoInput from "./TipoAbbTipoCorsoInput";
import { connect } from "react-redux";

class TipologiaAbbInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
				id: this.props.selected.id,
				tipiCorsoDTO:this.props.tipiCorso,
	    		descrizione: this.props.selected.descrizione,
	    		numIngressiGiornalieri: this.props.selected.numIngressiGiornalieri,
	    		numIngressiSett: this.props.selected.numIngressiSett,
				numIngressiTotale: this.props.selected.numIngressiTotale,
				tipicorso:this.props.selected.tipiCorso,
	    		visible: false,
	    		messaggio:null,
				corsi:this.props.selected.corsi
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
		  this.setState({ [e.target.name]: e.target.value});
        var that=this
        clearTimeout(this.timer)
        this.timer=setTimeout(that.handleSubmit,2000)
	  }
	  

	  componentDidMount(){
		this.setState({tipiCorsoDTO:this.props.tipiCorso})
	  }

	handleSubmit() {
		
	    const { id,descrizione, numIngressiGiornalieri, numIngressiSett, numIngressiTotale } = this.state;

	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('descrizione', descrizione);
		formData.append('numIngressiGiornalieri', numIngressiGiornalieri);
		formData.append('numIngressiSett', numIngressiSett);
		formData.append('numIngressiTotale', numIngressiTotale);
		

		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
          formData.append('lic','000');
		  formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/aggiornaTipologiaAbbRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 6000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      }).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
		  });
	  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare la tipologia selezionata?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
          formData.append('lic','000');
		  formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/eliminaTipologiaAbbRest/'+this.state.id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 6000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
						this.props.refresh();
			          this.setState({ visible: false });
			        }).catch(err => {
						if (err.response){
						  alert(err.response.data.message);
						}
						else{
						  alert(err.response);
						}
					  });
			}
	  }

	render() {
		var style1={
				color:"white"
		}

	    return (
			<form >
				<span>{this.state.messaggio}</span>
				<div className="row">
					<div className="col-12">
						<label className="control-label">Descrizione</label>
						<input className="form-control" style={{width:'100%'}} type="text" onChange={this.update} name="descrizione" value={this.state.descrizione} required="required" />

					</div>
				</div>

				<div className="row">
					<div className="col-md-6">
					<table>
					<tr>
						<td>Ingressi Giornalieri</td>
						<td><input className="form-control" type="number" onChange={this.update} name="numIngressiGiornalieri" value={this.state.numIngressiGiornalieri} required="required"  /></td>
					<td/>
					</tr>
					<tr>
						<td>Ingressi Tot. Sett.</td>
						<td>
						<input className="form-control" type="number" onChange={this.update} name="numIngressiSett" value={this.state.numIngressiSett} required="required"  />
						</td>
						<td/>
					</tr>
					<tr>
						<td>Ingressi Totali</td>
						<td>
						<input className="form-control" type="number" onChange={this.update} name="numIngressiTotale" value={this.state.numIngressiTotale} required="required"  />
						</td>
						<td/>
					</tr>
				</table>
					</div>
					<div className="col-md-6">
	    		
						<table>
							{this.props.tipiCorso?this.props.tipiCorso.map(tipoCorsoDTO =>(
									<TipoAbbTipoCorsoInput selected={this.props.selected} corsi={this.props.selected.corsi} idtipoabb={this.state.id} tipocorso={tipoCorsoDTO} key={tipoCorsoDTO.id} email={this.props.email} />
								)
								)
							:null
							}
							</table>
							</div>
							</div>
							<div className="col">
								<a className="btn btn-danger" style={{color:"white"}} onClick={this.elimina}>Elimina</a>
							</div>
						
						<div className="row">
							<div className="col-sm-12">
								<hr />
							</div>
						</div>
				
				</form>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato
		}
	}
	
	export default connect(mapStateToProps, null)(TipologiaAbbInput)