import React from "react";
import axios from 'axios';
import '../../util/config'
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

var timer=0
export default function TipoAbbTipoCorsoInput({corsi, idtipoabb, tipocorso}) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [descrCorso, setdescrCorso] = useState("")
  const [free, setfree] = useState(false)
  const [valore, setvalore] = useState(0)

  function update() {
    
    var that=this
    clearTimeout(this.timer)
    timer=setTimeout(function() {
            const formData = new FormData();
            formData.append('idtipoabb', idtipoabb);
            formData.append('idtipocorso', tipocorso.id);
            formData.append('free', free?1:0);
            formData.append('valore', valore);
            formData.append('website', global.config.domain);
            formData.append('email',email);
          formData.append('lic','000');
		  formData.append('palestra',palestra.id);
		        axios.post(global.config.server+'/aggiornaTipoAbbTipoCorsoRest',formData)
            .then((result) => {
                
                that.setState({messaggio:result.data});
                  const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      Toast.fire({
                        type: 'success',
                        title: result.data
                      })
            })
        },1000)
	}
  
	useEffect(() =>{
          var trovato=false;
          if (corsi&&corsi.length>0){
            console.log("corsi")
            console.log(corsi)
            for (var i = 0; i < corsi.length; i++) {
                if (corsi[i].idtipocorso===tipocorso.id){
                  console.log("trovato: ")
                  console.log(corsi[i])
                    setdescrCorso(corsi[i].descrTipoCorso)
                    setvalore(corsi[i].numingressisett)
                    setfree(corsi[i].free)
                    trovato=true;
                    continue;
                }
            }
          }
          if(!trovato){
            setdescrCorso(tipocorso.nome)
            setvalore(0)
          }
 
    },[idtipoabb, tipocorso])


    return (
      <tr>
        <td>
       tipo abb {idtipoabb} * 
            tipo corso  {tipocorso.id} * free {free}
       Ingressi {descrCorso} sett.
        </td>
        <td>
          <input className="form-control" type="number" name="valore" 
          onChange={(e) => setvalore(e.target.value)}  value={valore} required="required"  />
        </td>
        <td>
          <label className="control-label">Free <input className="form-control" type="checkbox" name="free" 
          onChange={(e) => e.target.checked?setfree(1):setfree(0)} checked={free?"checked":""} value={free?1:0}/></label>
        </td>
        <td><button onClick={() => update()}>salva</button></td>
        </tr>
    )
}